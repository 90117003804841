import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Footer, View } from '../../components'
import { useColors } from '../../constants'

import styles from '../pages.module.sass'

export const NotFound = () => {
	const navigate = useNavigate()
	const colors = useColors()
	return (
		<View className={styles.notFoundOuter}>
			<View className={styles.notFoundInner}>
				<View className={styles.notFoundText}>Device Not Found</View>
				<Button
					containerClassName={styles.notFoundButton}
					color={colors.white}
					text={'Go Back'}
					onClick={() => navigate(-1)}
				/>
			</View>
			<Footer />
		</View>
	)
}
