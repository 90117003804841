import { RichTextEditor } from '@mantine/rte'
import { DocPrivacy } from 'idl'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createDocFunction, getDocBySlugFunction, updateDocFunction } from '../../client'
import { Button, Footer, Loading, View } from '../../components'
import Logo from '../../components/icons/Logo'
import { useColors } from '../../constants'
import { Styles, useAuth } from '../../util'

import styles from '../pages.module.sass'
import './rte.sass'

const GENERIC_DOCS_ERROR = 'An error occurred.'

export const Docs = () => {
	const { user, userToken } = useAuth()
	const navigate = useNavigate()
	const { slug } = useParams() as { slug: string }

	const colors = useColors()

	const [body, setBody] = useState('')
	const [privacy, setPrivacy] = useState<DocPrivacy>('PUBLIC')

	const [isEditing, setIsEditing] = useState(false)
	const [error, setError] = useState('')
	const [editLoading, setEditLoading] = useState(false)

	const canEdit = user && user.role === 'ADMIN'

	const { response, isLoading } = getDocBySlugFunction.useQuery(
		{
			slug,
		},
		{ doc: null },
		{
			dependencies: [slug],
			onSuccess: ({ doc }) => {
				if (doc) {
					setBody(doc.body)
				}
			},
			onSettled: () => setError(''),
		},
	)
	const doc = response?.doc

	const isValid = useMemo(() => slug && body && privacy, [slug, body, privacy])

	const handleEditButton = useCallback(async () => {
		setEditLoading(true)
		if (userToken) {
			if (isEditing && isValid) {
				if (doc) {
					try {
						const { success } = await updateDocFunction.call({ id: doc.id, slug, body, privacy, userToken })
						if (!success) {
							setError(GENERIC_DOCS_ERROR)
						} else {
							setIsEditing(false)
						}
					} catch {
						setError(GENERIC_DOCS_ERROR)
					}
				} else {
					try {
						await createDocFunction.call({ slug, body, privacy, userToken })
						setIsEditing(false)
					} catch {
						setError(GENERIC_DOCS_ERROR)
					}
				}
			} else if (!isEditing) {
				setIsEditing((e) => !e)
			}
		}
		setEditLoading(false)
	}, [userToken, isValid, isEditing, slug, body, privacy])

	return (
		<View className={styles.docsOuter}>
			{isLoading ? (
				<Loading text={'Loading doc...'} containerHeight={'80vh'} />
			) : (
				<View classNames={[styles.docsInner, 'container']}>
					<View className={styles.centerContainer}>
						<Logo size={75} />
					</View>
					<RichTextEditor
						id={slug}
						className={Styles.classNames(styles.doc, isEditing && styles.editing)}
						value={body}
						readOnly={!isEditing}
						onChange={setBody}
						controls={[
							['bold', 'italic', 'underline', 'link', 'image'],
							['unorderedList', 'h1', 'h2', 'h3'],
							['sup', 'sub'],
							['alignLeft', 'alignCenter', 'alignRight'],
							['code', 'codeBlock'],
						]}
						styles={{
							root: {
								border: isEditing ? `solid 1px ${colors.white}` : 'none',
							},
						}}
						sticky
					/>
					{error && <View className={styles.error}>{error}</View>}
					{canEdit && (
						<View className={styles.centerContainer}>
							<Button
								text={isEditing ? 'Done Editing' : 'Edit'}
								onClick={handleEditButton}
								disabled={!userToken || (isEditing ? !isValid : !canEdit)}
								loading={editLoading}
							/>
						</View>
					)}
					<Footer />
				</View>
			)}
		</View>
	)
}
