import {
	getAuth,
	RecaptchaVerifier,
	signInWithPhoneNumber,
	ApplicationVerifier,
	ConfirmationResult,
} from 'firebase/auth'
import _ from 'lodash'
import { useMemo } from 'react'
import { FirebaseAuthToken } from 'idl'

export const useRecaptcha = (signInButtonCls: string, callback?: (res: any) => void) => {
	const recaptchaVerifier = useMemo(
		() =>
			new RecaptchaVerifier(
				signInButtonCls,
				{
					size: 'invisible',
					callback,
				},
				getAuth(),
			),
		[signInButtonCls],
	)

	_.set(window, 'recaptchaVerifier', recaptchaVerifier)
	recaptchaVerifier.render().then((recaptchaWidgetId) => {
		_.set(window, 'recaptchaWidgetId', recaptchaWidgetId)
	})
}

export const signInWithPhone = async (phone: string) => {
	const appVerifier = _.get(window, 'recaptchaVerifier') as ApplicationVerifier
	if (!appVerifier) {
		throw new Error('Must use useRecaptcha to set recaptchaVerifier')
	}
	const confirmationResult = await signInWithPhoneNumber(getAuth(), phone, appVerifier)
	_.set(window, 'confirmationResult', confirmationResult)
	return confirmationResult
}

export const confirmPhoneAuthCode = async (code: string): Promise<FirebaseAuthToken> => {
	const confirmationResult = _.get(window, 'confirmationResult') as ConfirmationResult
	if (!confirmationResult) {
		throw new Error('Must call signInWithPhone to set confirmationResult')
	}

	const res = await confirmationResult.confirm(code)
	return res.user.getIdToken(true)
}
