type Environment = 'development' | 'production'

// COPY/PASTE YOUR CREDENTIALS BELOW
export const FIREBASE_CONFIG = {
	apiKey: 'AIzaSyB10LuwEjdVvDd1GMs1ljphIiPmRxo4vo4',
	authDomain: 'dall-efy.firebaseapp.com',
	projectId: 'dall-efy',
	storageBucket: 'dall-efy.appspot.com',
	messagingSenderId: '630883096683',
	appId: '1:630883096683:web:01fdafb5e5c3d284b7b92e',
	measurementId: 'G-C5P5E25KKH',
}

export const ENVIRONMENT = (): Environment =>
	window.location.host.startsWith('localhost') ? 'development' : 'production'

export const ENVIRONMENT_VALUE = (values: { development: any; production: any }) => values[ENVIRONMENT()]

export const API_URL = () =>
	ENVIRONMENT_VALUE({
		development: `http://localhost:5001/${FIREBASE_CONFIG.projectId}/us-central1/app`,
		production: `https://us-central1-${FIREBASE_CONFIG.projectId}.cloudfunctions.net/app/`,
	})
