import { AuthRequest, Protocol } from '../common'
import { GraphicId } from '../models'
import { GetPlaylistById, GetPlaylistBySlug, GetPlaylists, SearchPlaylists } from './playlist'

/**
 * LikeDislike
 */

export interface LikeDislikeRequest extends AuthRequest {
	id: GraphicId
}
export interface LikeDislikeResponse {
	success: boolean
	isLiked: boolean
}
export const LikeDislike = Protocol<LikeDislikeRequest, LikeDislikeResponse>({
	path: '/graphic/likeDislike',
	authRequired: true,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})

/**
 * UpdateGraphicIndex
 */

export interface UpdateGraphicIndexRequest extends AuthRequest {
	id: GraphicId
	currentIndex: number
}
export interface UpdateGraphicIndexResponse {
	success: boolean
}
export const UpdateGraphicIndex = Protocol<UpdateGraphicIndexRequest, UpdateGraphicIndexResponse>({
	path: '/graphic/updateGraphicIndex',
	authRequired: true,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})

/**
 * DeleteGraphic
 */

export interface DeleteGraphicIndexRequest extends AuthRequest {
	id: GraphicId
}
export interface DeleteGraphicResponse {
	success: boolean
}
export const DeleteGraphic = Protocol<DeleteGraphicIndexRequest, DeleteGraphicResponse>({
	path: '/graphic/deleteGraphic',
	authRequired: true,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})
