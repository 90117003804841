import { TextInput } from '@mantine/core'
import { ConfirmationResult, getAuth, PhoneAuthProvider, RecaptchaVerifier, signInWithCredential } from 'firebase/auth'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import PhoneInput, { isValidPhoneNumber, Value as PhoneNumberType } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import { signInUserFunction } from '../../client/user'
import { Button, ConfirmCodePopup, Footer, Interactable, PopupToggleFunc, View } from '../../components'
import { signInWithPhone, Styles, useAuth } from '../../util'

import styles from '../pages.module.sass'

export const SignIn = () => {
	const navigate = useNavigate()
	const { auth } = useAuth()
	const { call: signUpWithToken, isLoading: isSignUpLoading } = signInUserFunction.useMutation()
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumberType | undefined>()
	const [final, setFinal] = useState<ConfirmationResult | null>(null)
	const [phoneError, setPhoneError] = useState('')
	const [error, setError] = useState('')

	const sendCodeToPhone = useCallback(() => {
		if (!phoneNumber || !isValidPhoneNumber(phoneNumber.toString())) {
			setPhoneError('Please enter a valid phone number')
			return
		}

		const verify = new RecaptchaVerifier(
			'recaptcha-container',
			{
				size: 'invisible',
			},
			getAuth(),
		)
		_.set(window, 'recaptchaVerifier', verify)
		signInWithPhone(phoneNumber)
			.then((result: ConfirmationResult) => {
				setFinal(result)
				toggleConfirmCodePopup(true)
			})
			.catch((err) => {
				setError(`Error Trying to send code, ${JSON.stringify(err)}`)
			})
	}, [auth, phoneNumber])

	const signInWithToken = useCallback(
		async (code: string) => {
			if (code === null || final === null || !phoneNumber) return
			try {
				const credential = PhoneAuthProvider.credential(final.verificationId, code)
				await signInWithCredential(getAuth(), credential)
				toggleConfirmCodePopup(false)
				navigate('/onSignIn')
			} catch (err) {
				setError(`Error trying to sign in, please try again, ${JSON.stringify(err)}`)
			}
		},
		[phoneNumber, signUpWithToken],
	)

	useEffect(() => {
		if (phoneError && phoneNumber && isValidPhoneNumber(phoneNumber.toString())) {
			setPhoneError('')
		}
	}, [phoneNumber])

	useEffect(() => {
		if (auth) {
			navigate('/onSignIn')
		}
	}, [auth])

	let toggleConfirmCodePopupRef: PopupToggleFunc
	const toggleConfirmCodePopup = (open: boolean) => {
		toggleConfirmCodePopupRef && toggleConfirmCodePopupRef(open)
	}

	return (
		<View className={styles.signInOuter}>
			<View classNames={[styles.playlistInner, 'container']}>
				<View className={styles.addPlaylistText}>Sign In</View>
				<View className={styles.inputerContainer}>
					<View className={styles.inputerContainer}>
						<View className={styles.inputHeader}>My Info</View>
						<View className={Styles.classNames(styles.helpText, styles.padDown)}>
							Please sign in so we can save your playlist to your account.
						</View>
						<PhoneInput
							defaultCountry={'US'}
							placeholder="Enter phone number"
							value={phoneNumber}
							onChange={setPhoneNumber}
							className={Styles.classNames(styles.textInput, styles.padDown)}
							inputComponent={TextInput}
							error={phoneError}
						/>
					</View>
				</View>
				<View>
					<View className={styles.addPlaylistButtonOuter}>
						<Button
							className={styles.signUpAndAddPlaylist}
							text={'Continue'}
							loading={isSignUpLoading}
							onClick={sendCodeToPhone}
						/>
						{error && <View className={styles.sinupError}>{error}</View>}
					</View>
					<View id="recaptcha-container"></View>
					<View className={styles.termsAndConditions}>
						By continuing, you hereby agree to the{' '}
						<Interactable className={styles.link} href={'/docs/privacy'} newTab inline>
							Privacy Policy
						</Interactable>{' '}
						and{' '}
						<Interactable className={styles.link} href={'/docs/terms'} newTab inline>
							Terms
						</Interactable>
						.
					</View>
				</View>
			</View>
			<Footer />
			<ConfirmCodePopup
				toggleRef={(t) => (toggleConfirmCodePopupRef = t)}
				onSubmit={(code) => signInWithToken(code)}
				onCancel={() => toggleConfirmCodePopup(false)}
				isLoading={isSignUpLoading}
			/>
		</View>
	)
}
