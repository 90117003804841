import { useEffect } from 'react'
import useDarkMode from 'use-dark-mode'

const INITIAL_STATE = false
const DARK_MODE = 'DARK_MODE'

export class DarkMode {
	public static use = () => {
		const darkMode = useDarkMode(false, {
			storageKey: DARK_MODE,
		})
		useEffect(() => {
			darkMode.disable() // remove to enable dark mode
			document.documentElement.style.setProperty('--color-scheme', darkMode.value ? 'dark' : 'light') // hard coding to light for now
		}, [darkMode.value])
		return darkMode
	}

	public static useValue = <T>(lightModeValue: T, darkModeValue: T): T => {
		const darkMode = DarkMode.use()
		return darkMode.value ? darkModeValue : lightModeValue
	}

	public static getUseValue = <T>(lightModeValue: T, darkModeValue: T): (() => T) => {
		return () => DarkMode.useValue(lightModeValue, darkModeValue)
	}
}
