import React, { memo } from 'react'
import { IconProps, ICON_HEIGHT, ICON_WIDTH } from '.'

export const Play = memo((props: IconProps) => (
	<svg
		className={props.className}
		width={props.width ?? props.size ?? ICON_WIDTH}
		height={props.height ?? props.size ?? ICON_HEIGHT}
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M16.5833 12.5V37.5L37.4166 25L16.5833 12.5Z" fill={props.fill} />
	</svg>
))

export default Play
