import { DatabaseModel } from '.'
import { UserId } from './User'

export type DocPrivacy = 'PUBLIC' | 'PRIVATE'
export const docPrivacies: DocPrivacy[] = ['PUBLIC', 'PRIVATE']

export interface Doc extends DatabaseModel {
	id: DocId
	slug: string
	creator: UserId
	body: string
	privacy: DocPrivacy
}

export type DocId = string
