import _ from 'lodash'

export * from './env'
export * from './helpers'
export * from './styles'
export * from './Hooks'
export * from './auth'
export * from './firebaseAuth'
export * from './firebaseClient'
export * from './DateTime'
export * from './Storage'

export const copyWithValuesDeleted = <T>(array: T[], ...values: T[]): T[] => {
	const newArray: any[] = _.cloneDeep(array)
	for (const value of values) {
		const index = array.indexOf(value)
		if (index >= 0) {
			newArray.splice(index, 1)
		}
	}
	return newArray
}

export const withKeysDeleted = (obj: any, keys: any[]): any => {
	const newObj = {} as any
	for (const key in obj) {
		if (!keys.includes(key)) {
			newObj[key] = (obj as any)[key]
		}
	}
	return newObj
}

export const copyWithKeysDeleted = (obj: any, keys: any[]): any => {
	const newObj: any = _.cloneDeep(obj)
	for (const key of keys) {
		newObj[key] = null
	}
	const returnObj: any = {}
	for (const k in newObj) {
		if (_.has(newObj, k)) {
			returnObj[k] = newObj[k]
		}
	}
	return returnObj
}

export const zeroPad = (num: number, numZeros: number) => {
	const n = Math.abs(num)
	const zeros = Math.max(0, numZeros - Math.floor(n).toString().length)
	let zeroString = Math.pow(10, zeros).toString().substring(1)
	if (num < 0) {
		zeroString = '-' + zeroString
	}

	return zeroString + n
}
