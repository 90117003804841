import React, { memo } from 'react'
import { IconProps, ICON_HEIGHT, ICON_WIDTH } from '.'

export const Drag = memo((props: IconProps) => (
	<svg
		className={props.className}
		width={props.width ?? props.size ?? ICON_WIDTH}
		height={props.height ?? props.size ?? ICON_HEIGHT}
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M41.6667 14.5833C40.5161 14.5833 39.5833 15.5161 39.5833 16.6667C39.5833 17.8173 40.5161 18.75 41.6667 18.75C42.8173 18.75 43.75 17.8173 43.75 16.6667C43.75 15.5161 42.8173 14.5833 41.6667 14.5833Z"
			fill={props.fill}
			stroke={props.fill}
			strokeWidth="2"
		/>
		<path
			d="M41.6667 31.2501C40.5161 31.2501 39.5833 32.1828 39.5833 33.3334C39.5833 34.484 40.5161 35.4167 41.6667 35.4167C42.8173 35.4167 43.75 34.484 43.75 33.3334C43.75 32.1828 42.8173 31.2501 41.6667 31.2501Z"
			fill={props.fill}
			stroke={props.fill}
			strokeWidth="2"
		/>
		<path
			d="M24.9999 14.5833C23.8493 14.5833 22.9166 15.5161 22.9166 16.6667C22.9166 17.8173 23.8493 18.75 24.9999 18.75C26.1505 18.75 27.0833 17.8173 27.0833 16.6667C27.0833 15.5161 26.1505 14.5833 24.9999 14.5833Z"
			fill={props.fill}
			stroke={props.fill}
			strokeWidth="2"
		/>
		<path
			d="M24.9999 31.2501C23.8493 31.2501 22.9166 32.1828 22.9166 33.3334C22.9166 34.484 23.8493 35.4167 24.9999 35.4167C26.1505 35.4167 27.0833 34.484 27.0833 33.3334C27.0833 32.1828 26.1505 31.2501 24.9999 31.2501Z"
			fill={props.fill}
			stroke={props.fill}
			strokeWidth="2"
		/>
		<path
			d="M8.33341 14.5833C7.18282 14.5833 6.25008 15.5161 6.25008 16.6667C6.25008 17.8173 7.18282 18.75 8.33341 18.75C9.48401 18.75 10.4167 17.8173 10.4167 16.6667C10.4167 15.5161 9.48401 14.5833 8.33341 14.5833Z"
			fill={props.fill}
			stroke={props.fill}
			strokeWidth="2"
		/>
		<path
			d="M8.33341 31.2501C7.18282 31.2501 6.25008 32.1828 6.25008 33.3334C6.25008 34.484 7.18282 35.4167 8.33341 35.4167C9.48401 35.4167 10.4167 34.484 10.4167 33.3334C10.4167 32.1828 9.48401 31.2501 8.33341 31.2501Z"
			fill={props.fill}
			stroke={props.fill}
			strokeWidth="2"
		/>
	</svg>
))

export default Drag
