import React, { memo } from 'react'
import { IconProps, ICON_HEIGHT, ICON_WIDTH } from '.'

export const Next = memo((props: IconProps) => (
	<svg
		className={props.className}
		width={props.width ?? props.size ?? ICON_WIDTH}
		height={props.height ?? props.size ?? ICON_HEIGHT}
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.5833 14.5834V35.4167L29.1666 25L14.5833 14.5834ZM33.3333 35.4167V14.5834H29.1666V35.4167H33.3333Z"
			fill={props.fill}
		/>
	</svg>
))

export default Next
