import { TextInput } from '@mantine/core'
import { APIProvider, Model, ModelCredentials } from 'idl'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useColors } from '../../constants'
import { IconProps } from '../icons'
import OpenAI from '../icons/OpenAI'
import StabilityAI from '../icons/StabilityAI'
import { Interactable } from '../interactable'
import { DynamicClassName, DynamicClassNames, View } from '../view'

import styles from '../components.module.sass'

interface ModelCardProps {
	model: Model
	onSelect?: () => void
	credentials?: ModelCredentials
	showPlaceholder?: boolean
	setCredentials?: Dispatch<SetStateAction<ModelCredentials>>
	selected?: boolean
	className?: DynamicClassName
	classNames?: DynamicClassNames
}

export const ModelIcon = (props: IconProps & { provider: APIProvider }) =>
	props.provider === APIProvider.DALL_E_2 ? (
		<OpenAI {...props} />
	) : props.provider === APIProvider.LEXICA_ART ? (
		<StabilityAI {...props} />
	) : null

export const ModelCard = ({
	model,
	onSelect,
	selected = false,
	credentials,
	showPlaceholder,
	setCredentials,
	className,
	classNames,
}: ModelCardProps) => {
	const colors = useColors()
	const isFree = model.credentials === null

	const placeholder = useMemo(() => (showPlaceholder ? '•••••••••••••••' : undefined), [showPlaceholder])

	const inputSection = useMemo(() => {
		if (!setCredentials || model.credentials === null || !selected) {
			return null
		}
		return (
			<View className={styles.inputs}>
				{_.isArray(model.credentials) ? (
					model.credentials.map(({ variableName, displayName }) => (
						<TextInput
							placeholder={placeholder ?? displayName}
							onChange={(v) => setCredentials((c) => ({ ...(c as any), [variableName]: v.target.value }))}
							className={styles.input}
							value={typeof credentials === 'string' ? credentials : credentials?.[variableName] ?? ''}
						/>
					))
				) : _.isObject(model.credentials) ? (
					<TextInput
						placeholder={placeholder ?? model.credentials.displayName}
						onChange={(v) => setCredentials(v.target.value)}
						className={styles.input}
						value={
							typeof credentials === 'string'
								? credentials
								: credentials?.[model.credentials.variableName] ?? ''
						}
					/>
				) : null}
				{placeholder && (
					<View className={styles.reassurance}>
						You already have valid credentials for {model.displayName}, but you may update them above.
					</View>
				)}
				{model.credentials && (
					<Interactable className={styles.docs} href={model.docsUrl} unselectable newTab>
						How to link {model.displayName} to Dall·Efy
					</Interactable>
				)}
			</View>
		)
	}, [placeholder, credentials, selected, model.credentials, setCredentials])

	const canSelect = onSelect && !selected

	return (
		<Interactable
			hoverOpacity={1}
			clickOpacity={canSelect ? undefined : 1}
			disabledOpacity={canSelect ? undefined : 1}
			disabled={!canSelect}
			classNames={[
				styles.modelCardOuter,
				onSelect && styles.selectable,
				selected && onSelect && styles.selected,
				className,
				...(classNames ?? []),
			]}
			onClick={onSelect}
			tagType={'div'}
		>
			<View className={styles.leftCol} unselectable>
				<ModelIcon provider={model.provider} fill={colors.white} size={50} />
			</View>
			<View className={styles.rightCol}>
				<View className={styles.titleRow} unselectable>
					{onSelect && !selected ? (
						<View className={styles.title}>{model.displayName}</View>
					) : (
						<Interactable className={styles.title} href={model.url} newTab>
							{model.displayName}
						</Interactable>
					)}
					<View classNames={[styles.note, isFree || placeholder ? styles.free : styles.paid]}>
						{isFree || placeholder ? 'Available ✅' : 'Requires API Key'}
					</View>
				</View>
				<View className={styles.description} unselectable>
					{model.description}
				</View>
				{inputSection}
			</View>
		</Interactable>
	)
}
