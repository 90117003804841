/**
 * Stores the keys to repeatedly-accessed Storage variables.
 */
export const STORAGE_KEYS = {
	USER_TOKEN: 'user_token',
	USER_DATA: 'user_data',
	USER_ID: 'user_id',
	QUERIES: 'dallefy_queries',

	// Keep this in parity with use-dark-mode
	DARK_MODE: 'algoraven.dark_mode',

	join: (...keys: string[]) => keys.join('.'),
}

/**
 * Stores the keys to repeatedly-accessed Session variables.
 */
export const SESSION_KEYS = {
	IP_ADDRESS: 'algoraven.ip_address',
	SHOWED_LANDING: 'algoraven.showed_landing',
	SHOWED_INVITE_WELCOME: 'algoraven.showed_invite_welcome',
}

/**
 * List of storage keys that should not get cleared
 */
export const PERSISTENT_STORAGE_KEYS = [STORAGE_KEYS.DARK_MODE]
