export const SIZE = {
	XXS: 2,
	XXS2: 3,
	XS: 4,
	XS2: 6,
	S: 8,
	S2: 12,
	M: 16,
	M2: 24,
	L: 32,
	L2: 48,
	XL: 64,
	XL2: 96,
	XXL: 128,
	XXL2: 192,
}
