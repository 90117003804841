export * from './User'
export * from './Playlist'
export * from './Graphic'
export * from './Location'
export * from './API'
export * from './Docs'

export interface DatabaseModel {
	updatedAt?: number
	createdAt?: number
}
