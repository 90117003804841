import { PlaylistWithQueue } from 'idl'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Interactable, View } from '..'
import { PLACEHOLDER } from '../../assets/images'
import { Graphic } from '../visuals'

import styles from '../components.module.sass'

export const PlaylistCarousel: React.FC<{
	title: string
	playlists: PlaylistWithQueue[] | undefined
	className?: string
	showAddPlaylist?: boolean
}> = ({ title, playlists, className, showAddPlaylist }) => {
	const navigate = useNavigate()
	return (
		<View classNames={[styles.scrollOuter, className]}>
			<View className={styles.scrollText}>{title}</View>
			<View className={styles.scrollInner}>
				{playlists?.map((playlist) => {
					const currentGraphic = playlist.queue?.[0]
					const url = currentGraphic?.imageUrls?.[0]
					return (
						<Interactable
							onClick={() => navigate(`/d/${playlist.slug}`)}
							key={`graphic_image_${title}_${playlist.slug}`}
						>
							<View className={styles.imageOuter}>
								<Graphic src={url ?? PLACEHOLDER} alt={'Current Artwork'} classNames={[styles.image]} />
								<View className={styles.imageTitle}>{playlist.name}</View>
							</View>
						</Interactable>
					)
				})}
				{showAddPlaylist && (
					<Interactable onClick={() => navigate('/d')}>
						<View className={styles.imageOuter}>
							<View classNames={[styles.image, styles.addImage]}>+</View>
							<View classNames={[styles.imageTitle, styles.bold]}>Create a Playlist</View>
						</View>
					</Interactable>
				)}
			</View>
		</View>
	)
}
