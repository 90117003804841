import { Grid, Select, TextInput } from '@mantine/core'
import { ConfirmationResult, getAuth, PhoneAuthProvider, RecaptchaVerifier, signInWithCredential } from 'firebase/auth'
import { CreatePlaylistResponse, DecodedAPIKeys, ModelWithCredentials } from 'idl'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import PhoneInput, { isValidPhoneNumber, Value as PhoneNumberType } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import { createPlaylistFunction } from '../../client/playlists'
import { getUserByIdFunction, signInUserFunction } from '../../client/user'
import { Button, ConfirmCodePopup, Footer, Interactable, ModelPicker, PopupToggleFunc, View } from '../../components'
import { signInWithPhone, Styles, useAuth } from '../../util'

import styles from '../pages.module.sass'

export const slideShowSpeedData = [
	{ label: 'Manually only', value: null },
	{ label: 'Every 1 minute', value: 1 },
	{ label: 'Every 3 minutes', value: 3 },
	{ label: 'Every 5 minutes', value: 5 },
	{ label: 'Every 10 minutes', value: 10 },
]

export const AddPlaylist = () => {
	const navigate = useNavigate()
	const { auth, userToken } = useAuth()
	const { response: user } = getUserByIdFunction.useQuery({ id: auth?.uid }, undefined, {
		dependencies: [auth],
	})
	const { call: createPlaylist, isLoading } = createPlaylistFunction.useMutation()
	const { call: signUpWithToken, isLoading: isSignUpLoading } = signInUserFunction.useMutation()
	const [playlistName, setPlaylistName] = useState('')
	const [prompt, setPrompt] = useState('')
	const [slideShowSpeed, setSlideShowSpeed] = useState<number | null>(5)
	const [isPrivate, setIsPrivate] = useState(false)
	const [passcode, setPasscode] = useState('')
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumberType | undefined>()
	const [phoneError, setPhoneError] = useState('')
	const [final, setFinal] = useState<ConfirmationResult | null>(null)
	const [error, setError] = useState('')
	const [apiKeys, setApiKeys] = useState<DecodedAPIKeys | undefined>(undefined)
	const [areValidCredentials, setAreValidCredentials] = useState(false)
	const [model, setModel] = useState<ModelWithCredentials | null>(null)

	const [displayName, setDisplayName] = useState('')

	const finishAndCreatePlaylist = useCallback(
		async (userToken: string) => {
			if (!userToken) {
				setError('Must be logged in to create a playlist')
			} else if (!playlistName) {
				setError('Please fill out all fields')
			} else {
				createPlaylist({
					userToken,
					name: playlistName,
					autoSwitchMins: slideShowSpeed,
					privacy: isPrivate ? 'PRIVATE' : 'PUBLIC',
					passcode: isPrivate ? passcode : null,
					provider: model?.provider,
					apiKeys: areValidCredentials ? { [model?.provider as any]: model?.credentials } : undefined,
					prompt,
				})
					.then((res: CreatePlaylistResponse) => {
						navigate(`/d/${res.slug}`)
					})
					.catch(() => setError('Failed to create playlist'))
			}
		},
		[
			model,
			playlistName,
			slideShowSpeed,
			auth,
			isPrivate,
			passcode,
			prompt,
			createPlaylist,
			navigate,
			final,
			areValidCredentials,
		],
	)

	const sendCodeToPhone = useCallback(() => {
		if (!phoneNumber || !isValidPhoneNumber(phoneNumber.toString())) {
			setPhoneError('Please enter a valid phone number')
			return
		}
		setError('')

		const verify = new RecaptchaVerifier(
			'recaptcha-container',
			{
				size: 'invisible',
			},
			getAuth(),
		)
		_.set(window, 'recaptchaVerifier', verify)
		signInWithPhone(phoneNumber)
			.then((result: ConfirmationResult) => {
				setFinal(result)
				toggleConfirmCodePopup(true)
			})
			.catch((err) => {
				setError(`Error Trying to send code, ${JSON.stringify(err)}`)
			})
	}, [auth, phoneNumber])

	const confirmCode = useCallback(
		async (code: string) => {
			if (final === null) {
				setError('Missing confirmation result')
				return
			}
			try {
				const credential = PhoneAuthProvider.credential(final.verificationId, code)
				const result = await signInWithCredential(getAuth(), credential)
				const userToken = await result.user.getIdToken()
				toggleConfirmCodePopup(false)
				finishSignUpWithToken(userToken)
			} catch (err) {
				setError(`Error trying to sign in, please try again, ${JSON.stringify(err)}`)
			}
		},
		[signUpWithToken, final],
	)

	const finishSignUpWithToken = useCallback(
		async (userToken: string) => {
			const phoneNumberToSignIn = phoneNumber ? phoneNumber.toString() : auth?.phoneNumber
			if (!phoneNumberToSignIn || !isValidPhoneNumber(phoneNumberToSignIn)) {
				setPhoneError('Please enter a valid phone number')
				return
			}
			signUpWithToken({
				authToken: userToken,
				phone: phoneNumberToSignIn,
				displayName,
				apiKeys,
			})
				.then(() => {
					finishAndCreatePlaylist(userToken)
				})
				.catch((err) => {
					setError(`Error trying to sign in, please try again, ${JSON.stringify(err)}`)
				})
		},
		[displayName, phoneNumber, apiKeys, signUpWithToken],
	)

	useEffect(() => {
		if (phoneError && phoneNumber && isValidPhoneNumber(phoneNumber.toString())) {
			setPhoneError('')
		}
	}, [phoneNumber])

	const onSpeedChange = useCallback((value: string) => {
		const speedMin = slideShowSpeedData.find((d) => d.label === value)?.value ?? null
		setSlideShowSpeed(speedMin)
	}, [])

	let toggleConfirmCodePopupRef: PopupToggleFunc
	const toggleConfirmCodePopup = (open: boolean) => {
		toggleConfirmCodePopupRef && toggleConfirmCodePopupRef(open)
	}

	const onPlaylistNameChange = useCallback((name: string) => {
		setPlaylistName(name)
	}, [])

	const valueDependentStyle = useCallback((...value: any) => {
		return { opacity: _.every(value) ? 1 : 0, height: _.every(value) ? 'auto' : 0 }
	}, [])

	return (
		<View className={styles.addPlaylistOuter}>
			<View classNames={[styles.playlistInner, 'container']}>
				<View className={styles.addPlaylistText}>Add a Playlist</View>
				<View className={styles.inputerContainer}>
					<View className={styles.inputHeader}>Name your Playlist</View>
					<TextInput
						placeholder="My Living Room TV"
						className={styles.textInput}
						onChange={(value) => onPlaylistNameChange(value.target.value)}
					/>
					{playlistName.length > 0 && playlistName.length < 4 && (
						<View className={styles.helpText} style={valueDependentStyle(playlistName)}>
							You're almost there! {4 - playlistName.length} more character
							{4 - playlistName.length === 1 ? '' : 's'}...
						</View>
					)}
				</View>
				<View
					className={styles.inputerContainer}
					style={valueDependentStyle(playlistName, playlistName.length > 3)}
				>
					<View className={styles.inputHeader}>Choose an Initial Art Prompt</View>
					<TextInput
						placeholder="Squirrel in a party hat"
						className={styles.textInput}
						onChange={(value) => setPrompt(value.target.value)}
					/>
					{prompt.length > 0 && prompt.length < 5 && (
						<View className={styles.helpText} style={valueDependentStyle(playlistName)}>
							You're almost there! {5 - prompt.length} more character{5 - prompt.length === 1 ? '' : 's'}
							...
						</View>
					)}
					<View className={styles.helpText}>This will be your first work of art on {playlistName}.</View>
				</View>
				<View
					className={styles.inputerContainer}
					style={valueDependentStyle(playlistName, playlistName.length > 3, prompt, prompt.length > 4)}
				>
					<View className={styles.inputHeader}>Choose an AI</View>
					<ModelPicker
						onChangeSelected={setModel}
						setAreValidCredentials={setAreValidCredentials}
						encodedCredentials={user?.__apiKeys}
					/>
				</View>
				<View
					className={styles.inputerContainer}
					style={valueDependentStyle(
						playlistName,
						playlistName.length > 3,
						prompt,
						prompt.length > 4,
						areValidCredentials,
					)}
				>
					<View className={styles.inputHeader}>Slides Rotate...</View>
					<Select
						data={slideShowSpeedData.map((item) => item.label)}
						onChange={onSpeedChange}
						value={slideShowSpeedData.find((d) => d.value === slideShowSpeed)?.label ?? 'Every 1 minute'}
						placeholder="Every 1 minute"
						className={styles.textInput}
					/>
					<View className={styles.helpText}>
						This is how frequently your playlist will move to the next art piece.
					</View>
				</View>
				<View
					className={styles.inputerContainer}
					style={valueDependentStyle(
						playlistName,
						playlistName.length > 3,
						prompt,
						prompt.length > 4,
						areValidCredentials,
						slideShowSpeed,
					)}
				>
					<View className={styles.inputHeader}>Set Privacy</View>
					<View className={styles.privacyContainer}>
						<Grid>
							<Grid.Col span={isPrivate ? 6 : 12}>
								<Select
									style={{ zIndex: 2, maxWidth: '100%' }}
									data={['Private', 'Public']}
									value={isPrivate ? 'Private' : 'Public'}
									onChange={(value) => value && setIsPrivate(value === 'Private')}
								/>
							</Grid.Col>
							{isPrivate && (
								<Grid.Col span={6}>
									<TextInput
										placeholder="Passcode"
										className={styles.textInput}
										disabled={!isPrivate}
										style={{ width: '100%' }}
										onChange={(value) => setPasscode(value.target.value)}
									/>
								</Grid.Col>
							)}
						</Grid>
					</View>
					<View className={styles.helpText}>
						{isPrivate
							? 'People need a passcode to queue art to my playlist.'
							: 'Anyone can queue art to my playlist.'}
					</View>
				</View>
				{!user && (
					<View
						className={styles.inputerContainer}
						style={valueDependentStyle(
							playlistName,
							playlistName.length > 3,
							prompt,
							prompt.length > 4,
							areValidCredentials,
							isPrivate ? passcode : true,
						)}
					>
						<View className={styles.inputerContainer}>
							<View className={styles.inputHeader}>My Info</View>
							<View className={Styles.classNames(styles.helpText, styles.padDown)}>
								Please sign in so we can save your playlist to your account.
							</View>
							<TextInput
								placeholder="Display Name"
								className={Styles.classNames(styles.textInput, styles.padDown)}
								onChange={(value) => setDisplayName(value.target.value)}
							/>
							{!auth && (
								<PhoneInput
									defaultCountry={'US'}
									placeholder="Enter phone number"
									value={phoneNumber}
									onChange={setPhoneNumber}
									className={Styles.classNames(styles.textInput, styles.padDown)}
									inputComponent={TextInput}
									error={phoneError}
								/>
							)}
						</View>
						<View style={valueDependentStyle(...[auth || phoneNumber, displayName, !phoneError])}>
							<TextInput
								placeholder="DALL·E 2 API Key"
								className={styles.textInput}
								onChange={(value) => setApiKeys({ DALL_E_2: value.target.value })}
							/>
							<View className={styles.helpText}>
								<b>To get a DALL·E 2 API Key (Session Token):</b>
								<ol>
									<li>
										Go to{' '}
										<Interactable
											className={styles.link}
											href={'https://labs.openai.com/'}
											inline
											newTab
										>
											https://labs.openai.com/
										</Interactable>
										.
									</li>
									<li>
										<Interactable
											className={styles.link}
											href={'https://developer.chrome.com/docs/devtools/network/'}
											inline
											newTab
										>
											Open the Network tab
										</Interactable>{' '}
										in Developer Tools.
									</li>
									<li>Type a prompt and press "Generate."</li>
									<li>
										Look for a request to "https://labs.openai.com/api/labs/tasks" and open its
										request body.
									</li>
									<li>
										Find{' '}
										<View className={styles.code} inline>
											Authorization: Bearer SOME_TOKEN
										</View>
										. Copy{' '}
										<View className={styles.code} inline>
											SOME_TOKEN
										</View>{' '}
										and paste it above. (It starts with{' '}
										<View className={styles.code} inline>
											sess-
										</View>
										).
									</li>
								</ol>
							</View>
						</View>
					</View>
				)}
				<View
					style={valueDependentStyle(
						playlistName,
						playlistName.length > 3,
						prompt,
						prompt.length > 4,
						isPrivate ? passcode : true,
						...(auth
							? [true]
							: [phoneNumber, displayName, !phoneError, Object.keys(apiKeys ?? {}).length > 0]),
					)}
				>
					<View className={styles.addPlaylistButtonOuter}>
						<Button
							className={styles.signUpAndAddPlaylist}
							text={user ? 'Create Playlist' : 'Sign Up and Create Playlist'}
							loading={isLoading}
							onClick={
								auth && userToken
									? user
										? () => finishAndCreatePlaylist(userToken)
										: () => finishSignUpWithToken(userToken)
									: sendCodeToPhone
							}
						/>
						{error && <View className={styles.sinupError}>{error}</View>}
					</View>
					<View id="recaptcha-container"></View>
					<View className={styles.termsAndConditions}>
						By entering, you hereby agree to the{' '}
						<Interactable className={styles.link} href={'/docs/privacy'} newTab inline>
							Privacy Policy
						</Interactable>{' '}
						and{' '}
						<Interactable className={styles.link} href={'/docs/terms'} newTab inline>
							Terms
						</Interactable>
						.
					</View>
				</View>
				<Footer />
			</View>
			<ConfirmCodePopup
				toggleRef={(t) => (toggleConfirmCodePopupRef = t)}
				onSubmit={(code) => confirmCode(code)}
				onCancel={() => toggleConfirmCodePopup(false)}
				isLoading={isSignUpLoading}
			/>
		</View>
	)
}
