import { cancelable, CancelablePromise } from 'cancelable-promise'
import { Nullable, ProtocolFunctionInterface, Undefinable } from 'idl'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
	QueryClient,
	QueryClientProvider,
	useMutation as useReactMutation,
	useQuery as useReactQuery,
	useQueryClient as useFunctionClient,
} from 'react-query'
import { copyWithKeysDeleted } from '../../util'
import { v1ClientFallback } from '../client'

export const FunctionClient = QueryClient
export const FunctionClientProvider = QueryClientProvider

export const functionClient = new FunctionClient()

export interface MutationOptions {
	extraCacheKeys?: string[]
	retries?: number
}

export interface MutationHook<RequestType, ResponseType> {
	call: (body: RequestType, initialValue?: ResponseType) => CancelablePromise<ResponseType>
	mutate: (res: ResponseType | undefined) => void
	error?: Error
	clearError: () => void
	isError: boolean
	isLoading: boolean
	isSuccess: boolean
	reset: () => void
}

export interface QueryOptions<ResponseType> {
	pollIntervalMs?: number
	refetchOnInteraction?: boolean
	enabled?: any[] | boolean
	dependencies?: any[]
	retries?: number
	persist?: 'storage' | 'session'
	onSuccess?: (res: ResponseType) => void
	onError?: (e: Error) => void
	onSettled?: (res: Undefinable<ResponseType>, e: Nullable<Error>) => void
}

export interface QueryHook<RequestType, ResponseType> {
	response?: ResponseType
	error?: Error
	clearError: () => void
	isLoading: boolean
	isError: boolean
	isFetched: boolean
	isFetching: boolean
	isRefetching: boolean
	isStale: boolean
	isSuccess: boolean
	refetch: () => void
	clear: () => void
	mutate: (res: ResponseType) => void
}

const getCacheKeys = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
	body: Partial<RequestType> = {},
	extraCacheKeys: string[] = [],
): string[] => {
	let cacheKeys = [protocol.options.cacheKey]
	if (body) {
		const cacheBody = copyWithKeysDeleted(body, protocol.options.cacheIgnoreProps)
		const cacheBodyKeys = Object.keys(cacheBody).sort()
		for (const cacheBodyKey of cacheBodyKeys) {
			const cacheKey = cacheBody[cacheBodyKey]
			if (!cacheKey) {
				cacheKeys.push(cacheKey)
			}
		}
	}
	cacheKeys = cacheKeys.concat(extraCacheKeys)
	return cacheKeys
}

const useMutation = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
	options: MutationOptions = {},
): MutationHook<RequestType, ResponseType> => {
	const functionClient = useFunctionClient()
	const mutationKey = getCacheKeys(protocol, undefined, options.extraCacheKeys)

	const mutation = useReactMutation(
		async (body: RequestType) =>
			v1ClientFallback(protocol, copyWithKeysDeleted(body, ['initialValue']), (body as any).initialValue),
		{
			mutationKey,
			retry: options.retries,
			onSuccess: (_, body: RequestType) => {
				functionClient.invalidateQueries(getCacheKeys(protocol, body, options.extraCacheKeys))
				for (const key of protocol.options.invalidatesCacheKeys || []) {
					functionClient.invalidateQueries([key])
				}
			},
		},
	)

	const [error, setError] = useState<Error | undefined>()
	useEffect(() => {
		setError(mutation.error as Error | undefined)
	}, [mutation.error])

	const mutate = (res: ResponseType | undefined) => functionClient.setQueryData(mutationKey, res)

	return {
		call: (body: RequestType, initialValue?: ResponseType) =>
			cancelable(mutation.mutateAsync({ ...body, initialValue })),
		mutate,
		error,
		clearError: () => setError(undefined),
		isError: mutation.isError,
		isLoading: mutation.isLoading,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
	}
}

const getMutationHook = <RequestType, ResponseType>(protocol: ProtocolFunctionInterface<RequestType, ResponseType>) => {
	return (options: MutationOptions = {}) => useMutation(protocol, options)
}

const useQuery = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
	body: Partial<RequestType>,
	initialValue?: ResponseType,
	options: QueryOptions<ResponseType> = {},
): QueryHook<RequestType, ResponseType> => {
	const functionClient = useFunctionClient()

	const queryKey = getCacheKeys(protocol, body)

	const [didFetch, setDidFetch] = useState(false)

	const enabledList = useMemo(
		() => (!options.enabled ? [] : typeof options.enabled === 'boolean' ? [options.enabled] : options.enabled!),
		[options.enabled],
	)
	const enabled = useMemo(() => enabledList.every(Boolean), [enabledList])
	const dependencies = useMemo(() => options.dependencies ?? [], [JSON.stringify(options.dependencies)])
	const onSuccess = useCallback(
		(res: ResponseType) => {
			options.onSuccess && options.onSuccess(res)
		},
		[options.onSuccess],
	)
	const onSettled = useCallback(
		(res: ResponseType | undefined, e: Error | null) => {
			options.onSettled && options.onSettled(res, e)
			setDidFetch(true)
		},
		[options.onSettled],
	)

	useEffect(() => {
		if (enabled && didFetch) {
			query.refetch()
		}
	}, [...dependencies, enabled])

	const query = useReactQuery(queryKey, async () => v1ClientFallback(protocol, body as RequestType, initialValue), {
		placeholderData: initialValue,
		staleTime: protocol.options.clientCacheMs,
		refetchInterval: options.pollIntervalMs,
		refetchOnWindowFocus: options.refetchOnInteraction,
		enabled,
		retry: options.retries,
		onSuccess,
		onSettled,
		onError: options.onError,
	})

	const [error, setError] = useState<Error | undefined>()
	useEffect(() => {
		setError(query.error as Error | undefined)
	}, [query.error])

	const mutate = useCallback((res: ResponseType) => functionClient.setQueryData(queryKey, res), [queryKey])

	return {
		response: query.data || initialValue,
		error,
		clearError: () => setError(undefined),
		isLoading: query.isLoading,
		isError: query.isError,
		isFetched: query.isFetched,
		isFetching: query.isFetching,
		isRefetching: query.isRefetching,
		isStale: query.isStale,
		isSuccess: query.isSuccess,
		refetch: query.refetch,
		clear: query.remove,
		mutate,
	}
}

const getQueryHook = <RequestType, ResponseType>(protocol: ProtocolFunctionInterface<RequestType, ResponseType>) => {
	return (body: Partial<RequestType>, initialValue?: ResponseType, options: QueryOptions<ResponseType> = {}) =>
		useQuery(protocol, body, initialValue, options)
}

const useCall = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
	body: Partial<RequestType>,
	initialValue?: ResponseType,
	options: QueryOptions<ResponseType> = {},
) => {
	return useQuery(protocol, body, initialValue, options).response
}

const getCallHook = <RequestType, ResponseType>(protocol: ProtocolFunctionInterface<RequestType, ResponseType>) => {
	return (body: Partial<RequestType>, initialValue?: ResponseType, options: QueryOptions<ResponseType> = {}) =>
		useCall(protocol, body, initialValue, options)
}

const getCall = <RequestType, ResponseType>(protocol: ProtocolFunctionInterface<RequestType, ResponseType>) => {
	return (
		body: RequestType,
		defaultValue?: ResponseType,
		options: QueryOptions<ResponseType> = {},
	): CancelablePromise<ResponseType> => {
		const queryKey = getCacheKeys(protocol, body)
		if (protocol.options.clientCacheMs) {
			const res = functionClient.getQueryData<ResponseType>(queryKey, {
				stale: false,
			})
			if (res) {
				return cancelable(new Promise((resolve) => resolve(res)))
			}
		}
		return cancelable(
			functionClient.fetchQuery<ResponseType>(
				queryKey,
				async () => v1ClientFallback(protocol, body as RequestType, defaultValue),
				{
					staleTime: protocol.options.clientCacheMs,
					retry: options.retries,
				},
			),
		)
	}
}

export const ProtocolFunction = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
) => {
	return {
		call: getCall(protocol),
		useQuery: getQueryHook(protocol),
		useCall: getCallHook(protocol),
		useMutation: getMutationHook(protocol),
	}
}

type WatchProtocolRequestType<RequestType, ResponseType> = RequestType & {
	onSnapshotUpdate: (response: ResponseType) => void
}

export const subscripeToProtocolFunction = <RequestType, ResponseType>(
	watchFunction: (args: WatchProtocolRequestType<RequestType, ResponseType>) => () => void,
	args: RequestType,
	deps: any[],
) => {
	const [response, setResponse] = useState<ResponseType | null>()
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		const playlistWatch = watchFunction({ ...args, onSnapshotUpdate: setResponse })
		return () => playlistWatch()
	}, deps)
	useEffect(() => {
		if (response || response === null) {
			setIsLoading(false)
		}
	}, [response])
	return { data: response, isLoading }
}
