import _ from 'lodash'
import { useMemo } from 'react'
import { DarkMode } from '../util/DarkMode'

export const lightModeColors = {
	// Shades
	black: '#000000',
	dark: '#222222',
	medium: '#ABA1B5',
	semilight: '#E2DFE5',
	light: '#F3F3F3',
	white: '#FFFFFF',

	// Colors
	primary: '#2FC078',
	secondary: '#8C45C3',
	tertiary: '#379FFF',
	success: '#009618',
	failure: '#D40000',
	warning: '#FFDA55',
	danger: '#E89C21',

	// Other
	clear: 'transparent',
}

export const darkModeColors = {
	// Shades
	black: '#FFFFFF',
	dark: '#FFFFFF',
	medium: '#AAAAAA',
	semilight: '#424242',
	light: '#202020',
	white: '#181818',

	// Colors
	primary: '#2FC078',
	secondary: '#6C64DE',
	tertiary: '#41C6FF',
	success: '#00DB23',
	failure: '#FF1D1D',
	warning: '#FFDA55',
	danger: '#E89C21',

	// Other
	clear: 'transparent',
}

export type DynamicColor = (lightModeColor: string, darkModeColor: string) => string

export const useColors = () => {
	const darkMode = DarkMode.use()
	const extendedColors = useMemo(() => {
		const colors = darkMode.value ? darkModeColors : lightModeColors
		const multiplier = darkMode.value ? 30 : 50
		const adjusted = {} as any
		for (const color in colors) {
			if (_.has(colors, color)) {
				const adjustedColor = {} as any
				const baseColor = (colors as any)[color]
				adjustedColor.light = adjustBrightness(baseColor, multiplier * 1)
				adjustedColor.lighter = adjustBrightness(baseColor, multiplier * 2)
				adjustedColor.lightest = adjustBrightness(baseColor, multiplier * 3)
				adjustedColor.dark = adjustBrightness(baseColor, multiplier * -1)
				adjustedColor.darker = adjustBrightness(baseColor, multiplier * -2)
				adjustedColor.darkest = adjustBrightness(baseColor, multiplier * -3)
				adjusted[color] = adjustedColor
			}
		}
		const dynamic = (lightModeColor: string, darkModeColor: string) =>
			darkMode.value ? darkModeColor : lightModeColor
		return { ...colors, darkMode: darkModeColors, lightMode: lightModeColors, adjusted, dynamic }
	}, [darkMode.value])
	return extendedColors
}

export const adjustBrightness = (colorCode: string, amount: number) => {
	let usePound = false

	if (colorCode[0] === '#') {
		colorCode = colorCode.slice(1)
		usePound = true
	}

	const num = parseInt(colorCode, 16)

	let r = (num >> 16) + amount
	if (r > 255) {
		r = 255
	} else if (r < 0) {
		r = 0
	}

	let g = (num & 0x0000ff) + amount
	if (g > 255) {
		g = 255
	} else if (g < 0) {
		g = 0
	}

	let b = ((num >> 8) & 0x00ff) + amount
	if (b > 255) {
		b = 255
	} else if (b < 0) {
		b = 0
	}

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}
