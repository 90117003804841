import _ from 'lodash'
import { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type Coords = {
	lat: number
	lng: number
}

export class Hooks {
	public static useSearchParam = (
		name: string,
		defaultValue?: string,
	): [string | undefined, (value?: string | null) => void] => {
		const [searchParams, setSearchParams] = useSearchParams()
		const [searchParam, _setSearchParam] = useState(searchParams.get(name) ?? defaultValue)
		useEffect(() => {
			_setSearchParam(searchParams.get(name) ?? defaultValue)
		}, [searchParams])
		const setSearchParam = useCallback(
			(value?: string | null) => {
				const searchParamObj = Object.fromEntries(searchParams.entries())
				if (_.isString(value)) {
					setSearchParams({ ...searchParamObj, [name]: value as string })
					_setSearchParam(value as string)
				} else {
					delete searchParamObj[name]
					setSearchParams(searchParamObj)
					_setSearchParam(undefined)
				}
			},
			[searchParams],
		)
		return [searchParam as string, setSearchParam]
	}

	public static useGeoLocation = (): [Coords | undefined, GeolocationPositionError | null] => {
		const [coords, setCoords] = useState<Coords | undefined>(undefined)
		const [error, setError] = useState<GeolocationPositionError | null>(null)
		useEffect(() => {
			window.navigator.geolocation.getCurrentPosition(
				(coords) => {
					setCoords({
						lat: coords.coords.latitude,
						lng: coords.coords.longitude,
					})
				},
				(error) => setError(error),
			)
		}, [])
		return [coords, error]
	}

	public static useFullscreen(elRef: RefObject<HTMLElement>): [boolean, () => void] {
		const [elementName, requestFuncName] = useMemo(() => {
			if (typeof document.fullscreenElement !== 'undefined') {
				return ['fullscreenElement', 'requestFullscreen']
			} else if (typeof (document as any).mozFullScreenElement !== 'undefined') {
				return ['mozFullScreenElement', 'mozRequestFullscreen']
			} else if (typeof (document as any).msFullscreenElement !== 'undefined') {
				return ['msFullscreenElement', 'msRequestFullscreen']
			} else if (typeof (document as any).webkitFullscreenElement !== 'undefined') {
				return ['webkitFullscreenElement', 'webkitRequestFullscreen']
			} else {
				throw new Error('fullscreenElement is not supported by this browser')
			}
		}, [document])

		const [isFullscreen, setIsFullscreen] = useState(document[elementName] != null)
		useEffect(() => {
			setIsFullscreen(document[elementName] != null)
		}, [elementName])

		const setFullscreen = () => {
			if (elRef.current == null) return

			elRef.current[requestFuncName]()
				.then(() => {
					setIsFullscreen(document[elementName] != null)
				})
				.catch(() => {
					setIsFullscreen(false)
				})
		}

		useLayoutEffect(() => {
			document.onfullscreenchange = () => setIsFullscreen(document[elementName] != null)
			return () => {
				document.onfullscreenchange = null
			}
		})

		return [isFullscreen, setFullscreen]
	}
}
