export enum APIProvider {
	DALL_E_2 = 'DALL_E_2',
	LEXICA_ART = 'LEXICA_ART',
}

export const apiProviders = [APIProvider.DALL_E_2, APIProvider.LEXICA_ART]

export type EncodedAPIKey = string
export type DecodedAPIKey = string

export type EncodedAPIKeys = Partial<{
	[APIProvider.DALL_E_2]: EncodedAPIKey
	[APIProvider.LEXICA_ART]: null
}>

export type DecodedAPIKeys = Partial<{
	[APIProvider.DALL_E_2]: DecodedAPIKey
	[APIProvider.LEXICA_ART]: null
}>
