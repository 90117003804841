import 'core-js/actual'

import polyfill from 'globalthis'
polyfill()

import 'polyfill-object.fromentries'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)
