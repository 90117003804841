import React, { memo } from 'react'
import { Styles, withKeysDeleted } from '../../util'
import { DynamicClassName, DynamicClassNames, View } from '../view'

import styles from './Graphic.module.sass'

export interface GraphicProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	classNames?: DynamicClassNames
	imgClassName?: DynamicClassName
	imgClassNames?: DynamicClassNames
}

export const Graphic = memo((props: GraphicProps) => {
	const { className, classNames = [], imgClassName, imgClassNames = [] } = props
	const imgStyles = Styles.classNames(styles.graphic, imgClassName, ...imgClassNames, className, ...classNames)
	const imgProps = withKeysDeleted(props, ['className', 'classNames', 'imgClassName', 'imgClassNames'])

	return (
		<View style={props.style} classNames={[styles.graphicOuter, className, ...classNames]}>
			<img {...imgProps} className={imgStyles} />
		</View>
	)
})
