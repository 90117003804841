import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPlaylistsFunction } from '../../client'
import { Footer, Loading, PlaylistCarousel, View } from '../../components'
import { Hooks, useAuth } from '../../util'

import styles from '../pages.module.sass'

const RANDOM_TITLE_TEXT = ['Welcome to Dall·Efy!', 'Hello from Dall·Efy!']

const RANDOM_LOADING_MESSAGES = [
	'Personalizing your experience...',
	'Rounding up the bots...',
	'Taming the machines...',
	'Loading some cool stuff...',
]

export const Home = () => {
	const { userToken } = useAuth()
	const location = useLocation()
	const [coords] = Hooks.useGeoLocation()

	const [isLoaded, setIsLoaded] = useState(false)
	const { response: allPlaylists } = getPlaylistsFunction.useQuery(
		{ coords, userToken: userToken ?? undefined },
		undefined,
		{
			pollIntervalMs: 60 * 1000,
			dependencies: [coords, userToken],
			onSettled: () => setIsLoaded(true),
		},
	)
	const [searchPlaylistId, setSearchPlaylistId] = React.useState('')

	const loadingMessage = useMemo(() => _.sample(RANDOM_LOADING_MESSAGES), [location.pathname])
	const greeting = useMemo(() => {
		const curTime = new Date().getHours()
		const timeBased =
			6 <= curTime && curTime < 12
				? 'Good morning!'
				: 12 <= curTime && curTime < 18
				? 'Good afternoon!'
				: 18 <= curTime && curTime < 20
				? 'Good evening!'
				: 20 <= curTime && curTime < 24
				? "It's arty time!"
				: 'Up late?'
		return _.sample([...RANDOM_TITLE_TEXT, timeBased])
	}, [location.pathname])

	return (
		<View className={styles.homePageOuter}>
			{!isLoaded ? (
				<View>
					<Loading text={loadingMessage} containerClassName={styles.loadingContainer} />
				</View>
			) : (
				<View classNames={['container', styles.homePageInner]}>
					<View>
						<View className={styles.welcomeText}>{greeting}</View>
						<PlaylistCarousel
							title={'👋 My Playlists'}
							playlists={allPlaylists?.created}
							className={styles.playlistCarousel}
							showAddPlaylist
						/>
						{allPlaylists?.public && allPlaylists.public.length > 0 && (
							<PlaylistCarousel
								title={'📢 Public Playlists'}
								playlists={allPlaylists?.public}
								className={styles.playlistCarousel}
							/>
						)}
						{allPlaylists?.nearby && allPlaylists.nearby.length > 0 && (
							<PlaylistCarousel
								title={'📍 Local Playlists'}
								playlists={allPlaylists?.nearby}
								className={styles.playlistCarousel}
							/>
						)}
					</View>
					{/* TODO: Full page search */}
					{/* <View className={styles.searchContainer}>
						<TextInput
							placeholder="Playlist ID"
							onChange={(value) => setSearchPlaylistId(value.target.value)}
							className={styles.search}
						/>
						<Button text={'Search'} onClick={() => navigate(`/d/${searchPlaylistId}`)} />
					</View> */}
				</View>
			)}
			<Footer />
		</View>
	)
}
