import React, { memo } from 'react'
import { IconProps, ICON_HEIGHT, ICON_WIDTH } from '.'

export const Previous = memo((props: IconProps) => (
	<svg
		className={props.className}
		width={props.width ?? props.size ?? ICON_WIDTH}
		height={props.height ?? props.size ?? ICON_HEIGHT}
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M33.3333 14.5834L18.7499 25L33.3333 35.4167V14.5834ZM18.7499 25V14.5834H14.5833V35.4167H18.7499V25Z"
			fill={props.fill}
		/>
	</svg>
))

export default Previous
