import { getAuth, onIdTokenChanged, User } from 'firebase/auth'
import { GetUserById, Nullable, Undefinable, User as DallefyUser, UserId } from 'idl'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { v1ClientFallback } from '../client/client'

const AuthContext = createContext<{
	auth: Nullable<User>
	userToken: Undefinable<string>
	userId: Nullable<UserId>
	user: Nullable<DallefyUser>
}>({
	auth: null,
	userToken: undefined,
	userId: null,
	user: null,
})

// 10 minutes
const FORCE_REFRESH_TIME = 10 * 60 * 1000

export const AuthProvider = ({ children }: any) => {
	const [auth, setAuth] = useState<Nullable<User>>(null)
	const [userId, setUserId] = useState<Nullable<UserId>>(null)
	const [userToken, setUserToken] = useState<Undefinable<string>>()
	const [user, setUser] = useState<Nullable<DallefyUser>>(null)

	useEffect(() => {
		const auth = getAuth()
		return onIdTokenChanged(auth, async (user) => {
			if (!user) {
				setAuth(null)
				setUserToken(undefined)
				return
			}

			const token = await user.getIdToken()
			setUserToken(token)
			setUserId(user.uid)
			setAuth(user)
		})
	}, [])

	useEffect(() => {
		const handle = setInterval(async () => {
			const user = getAuth().currentUser
			if (user) await user.getIdToken(true)
		}, FORCE_REFRESH_TIME)

		// clean up setInterval
		return () => clearInterval(handle)
	}, [])

	// Don't use react-query because of context and/or circular import issues
	useEffect(() => {
		if (userToken && userId) {
			v1ClientFallback(GetUserById, {
				userToken,
				id: userId,
			}).then(setUser)
		}
	}, [userToken, userId])

	const providerValue = useMemo(() => ({ auth, userToken, user, userId }), [auth, userToken, user, userId])

	return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
