import React, { memo } from 'react'
import ReactLoading from 'react-loading'
import { useColors } from '../../constants'
import { Styles } from '../../util'
import { View } from '../view'

import styles from '../components.module.sass'

interface LoadingProps {
	text?: string
	type?: 'balls' | 'bars' | 'bubbles' | 'cubes' | 'cylon' | 'spin' | 'spinningBubbles' | 'spokes'
	size?: number | string
	containerClassName?: string
	className?: string
	containerHeight?: number | string
	color?: string
}

export const Loading = memo((props: LoadingProps) => {
	const colors = useColors()
	const { color = colors.primary } = props
	return (
		<View
			className={Styles.classNames(styles.loadingOuter, props.containerClassName)}
			style={{ height: props.containerHeight }}
		>
			{props.text && (
				<View className={styles.loadingText} unselectable>
					{props.text}
				</View>
			)}
			<ReactLoading
				type={props.type || 'spin'}
				color={color}
				height={props.size ? props.size : '5vh'}
				width={props.size ? props.size : '5vh'}
				className={styles.loadingInner}
			/>
		</View>
	)
})
