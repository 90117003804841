import { getAuth } from 'firebase/auth'
import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, View } from '../../components'
import { useAuth } from '../../util'

import styles from '../components.module.sass'

export const Footer = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { auth } = useAuth()

	const logout = useCallback(() => getAuth().signOut(), [])
	const login = useCallback(() => navigate('/signIn'), [navigate])

	return (
		<View className={styles.footerOuter}>
			{location.pathname !== '/' && (
				<View className={styles.footerButtons}>
					<Button className={styles.footerButton} text={'Home'} onClick={() => navigate('/')} />
				</View>
			)}
			<View className={styles.footerText}>&copy; BitBird, Inc. 2022</View>
			{auth ? (
				<View className={styles.logoutText} onClick={logout}>
					Log Out
				</View>
			) : (
				<View className={styles.logoutText} onClick={login}>
					Log In
				</View>
			)}
		</View>
	)
}
