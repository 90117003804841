import { Select } from '@mantine/core'
import { GetPlaylistBySlugRequest, GetPlaylistBySlugResponse } from 'idl'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PLACEHOLDER } from '../../assets/images'
import { subscripeToProtocolFunction as subscribeToProtocolFunction } from '../../client'
import { watchPlaylist } from '../../client/playlists'
import { Graphic, Interactable, PlaylistQR, View } from '../../components'
import { Hooks } from '../../util'

import styles from '../pages.module.sass'

type ImageFit = 'fill' | 'contain' | 'cover'
const imageFits = ['fill', 'contain', 'cover']
const DEFAULT_IMAGE_FIT: ImageFit = 'cover'

export const PlaylistView = () => {
	const { slug } = useParams() as { slug: string }
	const [imageFit, setImageFit] = useState<ImageFit>(DEFAULT_IMAGE_FIT)

	const { data: response } = subscribeToProtocolFunction<GetPlaylistBySlugRequest, GetPlaylistBySlugResponse>(
		watchPlaylist,
		{ slug },
		[slug],
	)
	const playlist = useMemo(() => response?.playlist, [response])

	const displayView = useRef<HTMLDivElement>(null)
	const [isFullscreen, openFullscreen] = Hooks.useFullscreen(displayView)

	const [curTimeout, setCurTimeout] = useState<NodeJS.Timeout | null>(null)

	const [didInit, setDidInit] = useState(true)
	const [showText, setShowText] = useState(true)
	const [showOptions, setShowOptions] = useState(true)

	useEffect(() => {
		showTextTemp(!didInit)
		setDidInit(true)
	}, [playlist?.current?.id])
	const showTextTemp = useCallback(
		(withOptions: boolean = false) => {
			if (curTimeout !== null) {
				clearTimeout(curTimeout)
			}
			setShowText(true)
			setShowOptions(withOptions)
			const hideText = setTimeout(() => {
				setShowText(false)
			}, 5000)
			setCurTimeout(hideText)
			return () => {
				clearTimeout(hideText)
				setCurTimeout(null)
			}
		},
		[curTimeout],
	)

	const currentImage = playlist?.current?.imageUrls?.[playlist?.current.currentIndex ?? 0] ?? PLACEHOLDER
	const playlistUrl = playlist ? `/d/${playlist.slug}` : undefined

	return (
		<View
			className={styles.playlistViewOuter}
			onMouseMove={() => showTextTemp(true)}
			style={{
				cursor: showText ? 'default' : 'none',
			}}
		>
			<View ref={displayView} className={styles.display}>
				{playlist?.current && (
					<>
						<img
							src={currentImage}
							className={styles.image}
							onLoad={openFullscreen}
							onClick={playlist?.current ? openFullscreen : undefined}
							style={{ objectFit: imageFit }}
						/>
						<View onClick={openFullscreen} className={styles.text} style={{ opacity: showText ? 1 : 0 }}>
							<View className={styles.title}>{playlist.current.title}</View>
							<View className={styles.author}>{playlist.current.authorName}</View>
						</View>
						{showOptions && (
							<>
								<Select
									data={imageFits}
									onChange={(v) => setImageFit((v ?? DEFAULT_IMAGE_FIT) as ImageFit)}
									value={_.capitalize(imageFit)}
									placeholder={_.capitalize(imageFit)}
									className={styles.imageFitSelect}
									dropdownPosition={'bottom'}
									style={{ opacity: showText ? 1 : 0 }}
								/>
								<Interactable
									style={{ opacity: showText ? 1 : 0 }}
									className={styles.viewPlaylistButton}
									href={playlistUrl}
									newTab
								>
									<PlaylistQR
										className={styles.qr}
										playlist={playlist}
										size={200}
										dependencies={[isFullscreen]}
									/>
								</Interactable>
							</>
						)}
					</>
				)}
				<View
					className={styles.backgroundUnderlay}
					style={{ backgroundImage: currentImage ? `url('${currentImage}')` : undefined }}
				/>
				{playlist && !playlist?.current && (
					<View className={styles.noCurrent}>
						<View className={styles.title}>No images in playlist.</View>
						<View className={styles.subtitle}>
							Visit{' '}
							<Interactable className={styles.link} href={playlistUrl} newTab inline>
								{window.location.origin}/d/{playlist.slug}
							</Interactable>{' '}
							to queue up artwork.
						</View>
						<PlaylistQR className={styles.qr} playlist={playlist} size={300} />
						<Interactable classNames={[styles.bottomLink, styles.link]} href={`/`} inline>
							Back to Home
						</Interactable>
					</View>
				)}
			</View>
		</View>
	)
}
