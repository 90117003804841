import React, { memo } from 'react'
import { ColorIconProps, ICON_HEIGHT, ICON_WIDTH } from '.'

export const Logo = memo((props: ColorIconProps) => (
	<svg
		className={props.className}
		width={props.width ?? props.size ?? ICON_WIDTH}
		height={props.height ?? props.size ?? ICON_HEIGHT}
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.0499 50C11.2703 50 0 38.8445 0 25.0834C0 14.1294 8.64093 4.40619 18.4789 1.06668C18.7043 0.990159 18.928 0.912159 19.1496 0.834912C21.4054 0.0482977 23.4372 -0.660187 24.7995 1.06668C25.9078 3.14547 24.6981 4.77847 23.5723 6.29817C22.1627 8.20091 20.8847 9.92604 24.4526 12.1262C26.6636 13.4904 27.9586 11.8687 29.5401 9.88793C30.5392 8.63671 31.6526 7.24224 33.1836 6.36676C35.115 5.27156 38.9222 4.05046 41.6568 6.48774C46.7765 11.0509 50 17.6912 50 25.0834C50 38.8445 38.8295 50 25.0499 50ZM37.3211 24.0334C33.2116 26.4148 28.8263 26.5268 27.5263 24.2835C26.2263 22.0401 27.2818 20.2891 32.6133 15.9095C37.1469 11.5805 41.108 13.4161 42.4081 15.6595C43.7081 17.9028 41.4306 21.6519 37.3211 24.0334Z"
			fill={'#2FC078'}
		/>
	</svg>
))

export default Logo
