import { TextInput } from '@mantine/core'
import React, { memo, useState } from 'react'
import { useWindowSize } from 'use-hooks'
import { Button } from '../..'
import { viewports } from '../../../constants'
import { View } from '../../view'
import { Popup, PopupToggleFunc } from '../Popup'

import styles from '../../components.module.sass'

interface ConfirmCodePopupProps {
	toggleRef: (toggler: PopupToggleFunc) => void
	onSubmit: (code: string) => void
	onCancel: () => void
	isLoading: boolean
}

export const ConfirmCodePopup = memo((props: ConfirmCodePopupProps) => {
	const { toggleRef, isLoading } = props
	const [code, setCode] = useState('')
	const { width } = useWindowSize()

	return (
		<Popup
			open={false}
			toggleRef={(t) => {
				if (toggleRef) {
					toggleRef(t)
				}
			}}
			width={width < viewports.desktop ? '80vw' : '30vw'}
			className={styles.confirmCodePopupOuter}
		>
			<View className={styles.popupContainer}>
				<View className={styles.inputHeader}>Enter Confirmation Code</View>
				<View className={styles.inputContainerOuter}>
					<View className={styles.inputContainer}>
						<TextInput
							placeholder="Confirmation Code"
							className={styles.textInput}
							onChange={(value) => setCode(value.target.value)}
						/>
					</View>
				</View>
				<View className={styles.buttonContainer}>
					<Button
						text={'Confirm'}
						onClick={() => props.onSubmit(code)}
						className={styles.confirmButton}
						loading={isLoading}
					/>
				</View>
			</View>
		</Popup>
	)
})
