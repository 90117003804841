import React, { memo } from 'react'
import { IconProps, ICON_HEIGHT, ICON_WIDTH } from '.'

export const Pause = memo((props: IconProps) => (
	<svg
		className={props.className}
		width={props.width ?? props.size ?? ICON_WIDTH}
		height={props.height ?? props.size ?? ICON_HEIGHT}
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.6667 14.5834H22.9167V35.4167H16.6667V14.5834ZM27.0834 14.5834H33.3334V35.4167H27.0834V14.5834Z"
			fill={props.fill}
		/>
	</svg>
))

export default Pause
