import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore'
import {
	CreatePlaylist,
	DeletePlaylist,
	GetPlaylistBySlug,
	GetPlaylistBySlugRequest,
	GetPlaylistBySlugResponse,
	GetPlaylists,
	Graphic,
	Playlist,
	PlaylistWithQueue,
	ProgressQueue,
	QueueGraphic,
	UpdatePlaylist,
} from 'idl'
import _ from 'lodash'
import { firestoreDb } from '../util'
import { ProtocolFunction } from './util'

export const getPlaylistBySlugFunction = ProtocolFunction(GetPlaylistBySlug)

export const getPlaylistsFunction = ProtocolFunction(GetPlaylists)

export const updatePlaylistFunction = ProtocolFunction(UpdatePlaylist)

export const deletePlaylistFunction = ProtocolFunction(DeletePlaylist)

export const createPlaylistFunction = ProtocolFunction(CreatePlaylist)

export const queueGraphicFunction = ProtocolFunction(QueueGraphic)

export const progressQueueFunction = ProtocolFunction(ProgressQueue)

const PLAYLIST_COLLECTION = 'playlist'
const GRAPHIC_COLLECTION = 'graphic'

interface WatchPlaylistBySlugRequest extends GetPlaylistBySlugRequest {
	onSnapshotUpdate: (response: GetPlaylistBySlugResponse) => void
}

export const watchPlaylist = (args: WatchPlaylistBySlugRequest): (() => void) => {
	const q = query(collection(firestoreDb, PLAYLIST_COLLECTION), where('slug', '==', args.slug))
	return onSnapshot(
		q,
		async (snapshot) => {
			const playlists: Playlist[] = []
			snapshot.docs.forEach((doc) => {
				playlists.push(doc.data() as Playlist)
			})
			if (playlists.length === 0) {
				args.onSnapshotUpdate({ playlist: null })
			} else if (playlists.length > 1) {
				console.error('Multiple playlists with the same slug')
			} else {
				const playlist = playlists?.[0]
				const graphics = await Promise.all(
					playlist.queue.map(
						async (graphic) =>
							(await getDoc(doc(firestoreDb, GRAPHIC_COLLECTION, graphic))).data() as Graphic | undefined,
					),
				)
				const graphicMap = _.keyBy(graphics, 'id')
				const queue = playlist.queue.map((graphicId) => graphicMap[graphicId]).filter(Boolean) as Graphic[]
				const playlistWithQueue: PlaylistWithQueue = {
					...playlist,
					queue,
					current: queue?.[0] ?? null,
				}

				args.onSnapshotUpdate({ playlist: playlistWithQueue })
			}
		},
		console.error,
	)
}
