import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { PERSISTENT_STORAGE_KEYS, SHOUTS } from '../constants'
import { Shout } from './Shout'

interface StorageEvent<T> {
	key: string
	value: T | null
}

class StorageUtil {
	private instance: Storage

	constructor(storageInstance: Storage) {
		this.instance = storageInstance
	}

	// Set an object, string, or number in storage
	public set<T>(key: string, value: T) {
		this.instance.setItem(key, this.stringify(value))
		Shout.publish(SHOUTS.STORAGE, { key, value } as StorageEvent<T>)
	}

	// Get an object, string, or number from storage
	public get<T>(key: string): T | null {
		return this.parse<T>(this.instance.getItem(key))
	}

	// Check if an object exists in the storage
	public has(key: string) {
		return ['null', null].includes(this.instance.getItem(key))
	}

	// Remove an item from storage
	public remove(key: string) {
		this.instance.removeItem(key)
		Shout.publish(SHOUTS.STORAGE, { key, value: null })
	}

	// Clear storage
	public clear(prefix?: string) {
		// Keep persistent values
		const persistentStorageKeys = new Set(PERSISTENT_STORAGE_KEYS)
		for (let i = 0; i < this.instance.length; i++) {
			const key = this.instance.key(i)
			if (key && (!persistentStorageKeys.has(key) || (prefix && key.startsWith(prefix)))) {
				this.remove(key)
			}
		}
	}

	public use<T>(key: string, defaultValue: T | null = null): [T | null, (value: T) => void] {
		const [value, _setValue] = useState<T | null>(this.get(key) ?? defaultValue)
		const handler = useCallback(
			(payload: StorageEvent<T>) => {
				if (payload.key === key) {
					_setValue((v) => (!_.isEqual(payload.value, value) ? payload.value : v))
				}
			},
			[key, value],
		)
		useEffect(() => {
			Shout.subscribe(SHOUTS.STORAGE, handler)
			return () => Shout.unsubscribe(SHOUTS.STORAGE, handler)
		}, [handler])
		const setValue = useCallback((value: T) => this.set(key, value), [key])
		return [value as T | null, setValue]
	}

	private stringify = <T>(value: T): string => {
		if (_.isObject(value)) {
			// Item is an object
			return JSON.stringify(value)
		} else if (_.isNumber(value)) {
			// Item is a number
			return `${value}`
		} else {
			// Item is a string
			return value as any
		}
	}

	private parse = <T>(value: string | null): T | null => {
		if (!!value) {
			if (['null', 'undefined'].includes(value!)) {
				return null
			}
			try {
				// Try to parse the JSON object
				return JSON.parse(value!)
			} catch (e) {
				// Could not parse the item, that means its a string
				if (!isNaN(value as any)) {
					// Item is a number, return it parsed
					return +value! as any
				} else if (value === Boolean(value).toString()) {
					// Item is a boolean, return it as a boolean
					return Boolean(value) as any
				}
				// Item is not an object nor a string
				return value as any
			}
		}
		return null
	}
}

export const Storage = new StorageUtil(window.localStorage)
export const Session = new StorageUtil(window.sessionStorage)
