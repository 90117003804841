export class Colors {
	public static hexToRgb = (hex: string) => {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
		hex = hex.replace(shorthandRegex, (m, r, g, b) => {
			return r + r + g + g + b + b
		})
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
		const obj = result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null
		return obj
	}

	public static transparent = (color: string, alpha: number): string => {
		let rgb: any = {}
		try {
			rgb = Colors.hexToRgb(color)
			return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`
		} catch {
			return color
		}
	}
}
