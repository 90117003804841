import { OptionalAuthRequest, Protocol } from '../common'

export interface BaseEvent {
	eventName?: string
	firedAt?: number
}

/**
 * JobFunction
 */
export interface JobFunctionRequest extends OptionalAuthRequest {
	event: BaseEvent
}
export interface JobFunctionResponse {
	success: boolean
}
export const JobFunction = Protocol<JobFunctionRequest, JobFunctionResponse>({
	path: '/job',
	authRequired: false,
})
