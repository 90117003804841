import { AuthRequest, OptionalAuthRequest, Protocol } from '../common'
import {
	APIProvider,
	Coordinates,
	DecodedAPIKeys,
	GraphicId,
	PlaylistId,
	PlaylistPermissions,
	PlaylistPrivacy,
	PlaylistSettings,
	PlaylistWithQueue,
	QueueDirection,
	QueuePosition,
	UserId,
} from '../models'

/**
 * CreatePlaylist
 */

export interface CreatePlaylistRequest extends AuthRequest {
	name: string
	autoSwitchMins: number | null
	privacy: PlaylistPrivacy
	passcode: string | null
	prompt: string
	coords?: Coordinates
	// Defaults to APIProvider.DALL_E_2
	provider?: APIProvider
	/** Any partial/full updates to API keys */
	apiKeys?: DecodedAPIKeys
}
export interface CreatePlaylistResponse {
	id: PlaylistId
	slug: string
}
export const CreatePlaylist = Protocol<CreatePlaylistRequest, CreatePlaylistResponse>({
	path: '/playlist/createPlaylist',
	authRequired: true,
})

/**
 * GetPlaylistById
 */

export interface GetPlaylistByIdRequest extends OptionalAuthRequest {
	id: string
}
export interface GetPlaylistByIdResponse {
	playlist: PlaylistWithQueue | null
}
export const GetPlaylistById = Protocol<GetPlaylistByIdRequest, GetPlaylistByIdResponse>({
	path: '/playlist/getPlaylistById',
	clientCacheMs: 15000,
	authRequired: false,
})

/**
 * GetPlaylistBySlug
 */

export interface GetPlaylistBySlugRequest extends OptionalAuthRequest {
	slug: string
}
export interface GetPlaylistBySlugResponse {
	playlist: PlaylistWithQueue | null
}
export const GetPlaylistBySlug = Protocol<GetPlaylistBySlugRequest, GetPlaylistBySlugResponse>({
	path: '/playlist/getPlaylistBySlug',
	clientCacheMs: 15000,
	authRequired: false,
})

/**
 * GetPlaylists
 */

export interface GetPlaylistsRequest extends OptionalAuthRequest {
	coords?: Coordinates
}
export interface GetPlaylistsResponse {
	public: PlaylistWithQueue[]
	nearby: PlaylistWithQueue[]
	created: PlaylistWithQueue[]
}
export const GetPlaylists = Protocol<GetPlaylistsRequest, GetPlaylistsResponse>({
	path: '/playlist/getPlaylists',
	clientCacheMs: 15000,
	authRequired: false,
})

/**
 * SearchPlaylists
 */

export interface SearchPlaylistsRequest extends OptionalAuthRequest {
	prefix: string
}
export interface SearchPlaylistsResponse {
	playlists: PlaylistWithQueue[]
}
export const SearchPlaylists = Protocol<SearchPlaylistsRequest, SearchPlaylistsResponse>({
	path: '/playlist/searchPlaylists',
	serverCacheMs: 15000,
	clientCacheMs: 15000,
	authRequired: false,
})

/**
 * QueueGraphic
 */

export interface QueueGraphicRequest extends OptionalAuthRequest {
	id: PlaylistId
	title: string
	author?: UserId
	authorName?: string
	passcode?: string
	/** Defaults to 'end' */
	position?: QueuePosition
}
export interface QueueGraphicResponse {
	success: boolean
}
export const QueueGraphic = Protocol<QueueGraphicRequest, QueueGraphicResponse>({
	path: '/playlist/queueGraphic',
	authRequired: false,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})

/**
 * UpdatePlaylist
 */

export interface UpdatePlaylistRequest extends AuthRequest {
	id: PlaylistId
	name?: string
	slug?: string
	autoSwitchMins?: number | null
	privacy?: PlaylistPrivacy
	/** Passcode to update the playlist passcode to. */
	passcode?: string | null
	queue?: GraphicId[]
	coords?: Coordinates
	settings?: PlaylistSettings
	permissions?: PlaylistPermissions
	/** Passcode to update the queue. */
	enqueuePasscode?: string
	/** Any partial/full updates to API keys */
	apiKeys?: DecodedAPIKeys
}
export interface UpUpdatePlaylistResponse {
	id: PlaylistId
	slug: string
}
export const UpdatePlaylist = Protocol<UpdatePlaylistRequest, UpUpdatePlaylistResponse>({
	path: '/playlist/updatePlaylist',
	authRequired: true,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})

/**
 * DeletePlaylist
 */

export interface DeletePlaylistRequest extends AuthRequest {
	id: PlaylistId
}
export interface DeletePlaylistResponse {
	success: boolean
}
export const DeletePlaylist = Protocol<DeletePlaylistRequest, DeletePlaylistResponse>({
	path: '/playlist/deletePlaylist',
	authRequired: true,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})

/**
 * ProgressQueue
 */

export interface ProgressQueueRequest extends AuthRequest {
	id: PlaylistId
	direction: QueueDirection
	passcode?: string
}
export interface ProgressQueueResponse {
	success: boolean
}
export const ProgressQueue = Protocol<ProgressQueueRequest, ProgressQueueResponse>({
	path: '/playlist/progressQueue',
	authRequired: true,
	invalidatesCacheKeys: [
		GetPlaylistById.options.cacheKey,
		GetPlaylistBySlug.options.cacheKey,
		GetPlaylists.options.cacheKey,
		SearchPlaylists.options.cacheKey,
	],
})
