import {
	APIProvider,
	apiProviders,
	apiProviderToModel,
	DecodedAPIKeys,
	EncodedAPIKeys,
	ModelCredentials,
	ModelWithCredentials,
	Undefinable,
} from 'idl'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { DynamicClassName, DynamicClassNames, View } from '../view'
import { ModelCard } from './ModelCard'

import styles from '../components.module.sass'

interface ModelPickerProps {
	models?: APIProvider[]
	selectedModel?: APIProvider
	credentials?: DecodedAPIKeys
	/** Used when adding new playlists. Skip null-checking for already-valid models. */
	encodedCredentials?: EncodedAPIKeys
	setAreValidCredentials?: Dispatch<SetStateAction<boolean>>
	onChangeCredentials?: (model: DecodedAPIKeys) => void
	onChangeSelected?: (model: ModelWithCredentials) => void
	className?: DynamicClassName
	classNames?: DynamicClassNames
}

export const ModelPicker = ({
	models = apiProviders,
	selectedModel,
	credentials: initialCredentials,
	encodedCredentials,
	setAreValidCredentials,
	onChangeCredentials: onChangeCredentialsProp,
	onChangeSelected: onChangeSelectedProp,
	className,
	classNames,
}: ModelPickerProps) => {
	const modelObjects = useMemo(() => models.map((ap) => apiProviderToModel[ap]), [models])

	const [selected, setSelected] = useState<Undefinable<number>>(0)
	useEffect(
		() => setSelected(!selectedModel ? 0 : modelObjects.findIndex((m) => m.provider === selectedModel) ?? 0),
		[selectedModel, modelObjects],
	)

	const [credentials, setCredentials] = useState<DecodedAPIKeys>(initialCredentials ?? {})
	useEffect(() => {
		initialCredentials && setCredentials(initialCredentials)
	}, [initialCredentials])

	const areSingleModelCredentialsValid = useCallback(
		(apiProvider: APIProvider) => {
			const model = apiProviderToModel[apiProvider]
			if (!model.credentials || _.has(encodedCredentials, apiProvider)) {
				return true
			} else if (_.isArray(model.credentials)) {
				return Object.keys(model.credentials).every((key) => !!credentials?.[apiProvider]?.[key])
			}
			return !!credentials[apiProvider]
		},
		[encodedCredentials, credentials],
	)
	const areValidCredentials = useMemo(() => {
		if (onChangeSelectedProp && selected !== undefined) {
			return areSingleModelCredentialsValid(modelObjects[selected].provider)
		}
		return Object.keys(credentials).every((c) => areSingleModelCredentialsValid(c as APIProvider))
	}, [onChangeSelectedProp, areSingleModelCredentialsValid, credentials, selected])
	useEffect(() => setAreValidCredentials && setAreValidCredentials(areValidCredentials), [areValidCredentials])

	const onChangeSelected = useCallback(
		(index: number) => {
			setSelected(index)
			const model = _.clone(modelObjects[index]) as ModelWithCredentials
			model.credentials = credentials[model.provider] ?? null
			onChangeSelectedProp && onChangeSelectedProp(model)
		},
		[modelObjects, credentials, onChangeSelectedProp],
	)

	const onChangeCredentials = useCallback(
		(provider: APIProvider, creds: ModelCredentials) => {
			let newCredentials = { ...credentials, [provider]: creds }
			if (selected) {
				const provider = modelObjects[selected].provider
				newCredentials = { [provider]: newCredentials[provider] }
			}
			setCredentials(newCredentials)
			onChangeCredentialsProp && onChangeCredentialsProp(newCredentials)
		},
		[selected, modelObjects, credentials, onChangeCredentialsProp],
	)

	return (
		<View classNames={[styles.modelPickerOuter, className, ...(classNames ?? [])]}>
			{modelObjects.map((m, i) => (
				<ModelCard
					model={m}
					key={m.provider}
					onSelect={() => onChangeSelected(i)}
					credentials={credentials?.[m.provider]}
					showPlaceholder={!!encodedCredentials?.[m.provider]}
					setCredentials={(c) => onChangeCredentials(m.provider, c as ModelCredentials)}
					selected={selected === i}
				/>
			))}
		</View>
	)
}
