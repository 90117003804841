import React, { forwardRef, memo, ReactElement, useMemo } from 'react'
import { Interactable, InteractableProps } from '..'
import { SIZE, useColors } from '../../constants'
import { withKeysDeleted } from '../../util'
import { Loading } from '../animations'
import { DynamicClassNames, View } from '../view'
import { Graphic } from '../visuals'

import styles from '../components.module.sass'

const DEFAULT_IMAGE_SIZE = 30

interface ButtonProps extends Omit<InteractableProps, 'children'> {
	color?: string
	textColor?: string
	text: string
	loading?: boolean
	loadingText?: string
	icon?: ReactElement
	image?: string
	imageSize?: string | number
	inverted?: boolean
	plain?: boolean
	containerClassName?: string
	containerClassNames?: DynamicClassNames
	loadingColor?: string
	iconEnd?: ReactElement
	rounded?: boolean
}

export const Button = memo(
	forwardRef((props: ButtonProps, ref: any) => {
		const colors = useColors()
		const {
			color = colors.primary,
			textColor = colors.white,
			text,
			icon,
			image,
			imageSize = DEFAULT_IMAGE_SIZE,
			containerClassName,
			className,
			inverted,
			plain,
			containerClassNames = [],
			loading = false,
			loadingText,
			loadingColor = inverted ? colors.primary : colors.white,
			iconEnd,
			rounded = true,
		} = props
		const interactableProps = withKeysDeleted(props, [
			'styles',
			'text',
			'icon',
			'iconSize',
			'inverted',
			'containerClassName',
			'containerClassNames',
			'disabled',
			'color',
			'loading',
			'loadingColor',
			'iconEnd',
			'rounded',
		])
		const children = useMemo(
			() => (
				<>
					{!loading ? (
						<>
							{image && (
								<Graphic
									src={image}
									alt={text}
									className={styles.image}
									style={{ height: imageSize, width: imageSize }}
								/>
							)}
							{!image && icon ? <View className={styles.icon}>{icon}</View> : null}
							<View
								classNames={([styles.text, className] as DynamicClassNames).concat(
									props.classNames || [],
								)}
							>
								{text}
							</View>
							{iconEnd && <View className={styles.iconEnd}>{iconEnd}</View>}
						</>
					) : (
						<>
							{loadingText && (
								<View
									classNames={(
										[styles.text, styles.loadingText, className] as DynamicClassNames
									).concat(props.classNames || [])}
								>
									{loadingText}
								</View>
							)}
							<Loading size={20} color={loadingColor} />
						</>
					)}
				</>
			),
			[
				loading,
				loadingText,
				loadingColor,
				image,
				imageSize,
				icon,
				iconEnd,
				text,
				className,
				loadingText,
				props.classNames,
			],
		)
		return (
			<Interactable
				{...interactableProps}
				tagType={props.href ? 'a' : 'button'}
				classNames={(
					[
						styles.buttonComponentMain,
						inverted && styles.inverted,
						(image || icon) && styles.withImage,
						rounded && styles.rounded,
						containerClassName,
					] as DynamicClassNames
				).concat(containerClassNames)}
				style={{
					...props.style,
					color: inverted || plain ? color : textColor,
					background: inverted ? colors.white : plain ? 'none' : color,
					paddingLeft: plain ? 0 : SIZE.M,
					paddingRight: plain ? 0 : SIZE.M,
					borderColor: inverted ? color : colors.clear,
					fontWeight: inverted ? 700 : 400,
				}}
				disabled={props.disabled || loading}
				ref={ref}
				children={children}
			/>
		)
	}),
)
