import { AuthRequest, OptionalAuthRequest, Protocol } from '../common'
import { Coordinates, DecodedAPIKeys, FirebaseAuthToken, User, UserId, UserToken } from '../models'

/**
 * GetUserById
 */

export interface GetUserByIdRequest extends OptionalAuthRequest {
	id: UserId
}
export type GetUserByIdResponse = User | null
export const GetUserById = Protocol<GetUserByIdRequest, GetUserByIdResponse>({
	path: '/user/getUserById',
	clientCacheMs: 15000,
	authRequired: false,
})

/**
 * SignInUser
 */

export interface SignInUserRequest {
	authToken: FirebaseAuthToken
	phone: string
	displayName: string
	apiKeys?: DecodedAPIKeys
	coords?: Coordinates
}
export interface SignInUserResponse {
	id: UserId
	token: UserToken
}
export const SignInUser = Protocol<SignInUserRequest, SignInUserResponse>({
	path: '/user/signInUser',
	authRequired: false,
})

/**
 * UpdateUser
 */

export interface UpdateUserRequest extends AuthRequest {
	displayName?: string
	apiKeys?: DecodedAPIKeys
	coords?: Coordinates
}
export interface UpdateUserResponse {
	success: boolean
}
export const UpdateUser = Protocol<UpdateUserRequest, UpdateUserResponse>({
	path: '/user/updateUser',
	authRequired: true,
	invalidatesCacheKeys: [GetUserById.options.cacheKey],
})
