export class Shout {
	/**
	 * Broadcast a Shout with keyword arguments.
	 */
	public static publish = (name: string, kwargs: any = {}) => {
		const event = new CustomEvent(name, { detail: kwargs })
		window.dispatchEvent(event)
	}

	/**
	 * Listen to a Shout with a certain name and perform the callback when it occurs.
	 */
	public static subscribe = (name: string, callback: (kwargs?: any) => void) => {
		const shoutHandler = (event: Event) => {
			const kwargs = (event as any).detail
			callback(kwargs || {})
		}
		callback.prototype = { shoutHandler }
		window.addEventListener(name, callback.prototype.shoutHandler)
	}

	/**
	 * Stop listening to the shout. The callback must match the callback used in subscribe().
	 */
	public static unsubscribe = (name: string, callback: (kwargs?: any) => void) => {
		if (callback?.prototype?.shoutHandler) {
			window.removeEventListener(name, callback.prototype.shoutHandler)
		}
	}
}
