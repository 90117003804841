import React, { forwardRef, memo, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { withKeysDeleted } from '../../util'
import { DynamicClassNames, View, ViewProps } from '../view'
import { isMobile } from 'react-device-detect'

import styles from '../components.module.sass'

export interface InteractableProps extends ViewProps {
	disabled?: boolean
	clickOpacity?: number
	hoverMagnify?: number
	hoverOpacity?: number
	disabledOpacity?: number
	href?: string
	newTab?: boolean
	inline?: boolean
	tagType?: string
	classNames?: DynamicClassNames
}

const DEFAULTS = {
	HOVER_OPACITY: 0.6,
	HOVER_MAGNIFY: 1,
	CLICK_OPACITY: 0.4,
	DISABLED_OPACITY: 0.5,
}

const BaseInteractable = forwardRef((props: InteractableProps, ref: any) => {
	const { tagType = 'a', disabled = false, disabledOpacity = DEFAULTS.DISABLED_OPACITY } = props
	const [isMouseOver, setIsMouseOver] = useState(false)

	const onMouseOver = useCallback(
		(e: any) => {
			if (!isMouseOver) {
				setIsMouseOver(true)
				if (props.onMouseOver) {
					props.onMouseOver(e)
				}
			}
		},
		[props.onMouseOver, isMouseOver],
	)

	const onMouseOut = useCallback(
		(e: any) => {
			if (isMouseOver) {
				setIsMouseOver(false)
				if (props.onMouseOut) {
					props.onMouseOut(e)
				}
			}
		},
		[props.onMouseOut, isMouseOver],
	)

	const onMouseDown = useCallback(
		(e: any) => {
			if (!disabled) {
				if (props.onMouseDown) {
					props.onMouseDown(e)
				}
			}
		},
		[props.onMouseDown],
	)

	const onMouseUp = useCallback(
		(e: any) => {
			if (!disabled) {
				if (props.onMouseUp) {
					props.onMouseUp(e)
				}
			}
		},
		[disabled, props.onMouseUp],
	)

	const onClick = useCallback(
		(e: any) => {
			if (!disabled) {
				if (props.onClick) {
					props.onClick(e)
				}
			}
		},
		[props.onClick, disabled, window, props.href, props.newTab],
	)

	const viewProps = withKeysDeleted(props, [
		'disabled',
		'inline',
		'clickOpacity',
		'hoverMagnify',
		'hoverOpacity',
		'disabledOpacity',
		'href',
		'newTab',
		'onMouseOver',
		'onMouseOut',
		'tagType',
		'classNames',
	])

	return (
		<View
			{...viewProps}
			tagType={tagType}
			classNames={(props.classNames || [])
				.concat([
					props.className,
					props.disabled && styles.disabled,
					styles.interactableComponentMain,
					props.href && styles.href,
				])
				.concat(props.classNames as DynamicClassNames)}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseOut}
			onClick={onClick}
			ref={ref}
			href={props.disabled ? undefined : props.href}
			target={props.newTab ? '_blank' : undefined}
			rel={props.newTab ? 'noopener noreferrer' : undefined}
			children={props.children}
			style={{
				...props.style,
				opacity: disabled ? disabledOpacity ?? DEFAULTS.DISABLED_OPACITY : props.style?.opacity,
			}}
			disabled={disabled}
			animated
		/>
	)
})

const StyledInteractable = styled(BaseInteractable)`
	opacity: 1;
	transform: scale(1);
	display: ${(props) => (props.inline ? 'inline !important' : 'auto')};
	&:hover {
		opacity: ${(props) => (isMobile ? 1 : props.hoverOpacity || DEFAULTS.HOVER_OPACITY)};
		transform: scale(${(props) => (isMobile ? 1 : props.hoverMagnify || DEFAULTS.HOVER_MAGNIFY)});
	}
	&:active {
		opacity: ${(props) => props.clickOpacity || DEFAULTS.CLICK_OPACITY};
	}
`

export const Interactable = memo((props: InteractableProps) => {
	const ref = useRef() as React.MutableRefObject<HTMLDivElement>
	return <StyledInteractable {...{ ...props, ref }} />
})
