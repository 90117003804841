import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import ReactPopup from 'reactjs-popup'

import 'reactjs-popup/dist/index.css'
import { SHOUTS, useColors } from '../../constants'
import { Styles } from '../../util'
import { Colors } from '../../util/Colors'
import { Shout } from '../../util/Shout'
import { View } from '../view'

import styles from '../components.module.sass'

export type PopupToggleFunc = ((toggle: boolean) => void) | null

export interface PopupProps {
	children?: any
	open?: boolean
	autoclose?: boolean
	toggleRef?: (toggler: PopupToggleFunc) => void
	onOpen?: () => void
	onClose?: () => void
	width?: string | number
	minWidth?: string | number
	maxWidth?: string | number
	height?: string | number
	minHeight?: string | number
	maxHeight?: string | number
	className?: string
	containerClassName?: string
	innerClassName?: string
}

export const Popup = memo((props: PopupProps) => {
	const colors = useColors()
	const popupOverlayColor = Colors.transparent(colors.black, 0.8)
	const {
		autoclose = false,
		width = '60vw',
		height = 'auto',
		minWidth,
		minHeight,
		maxWidth,
		maxHeight,
		innerClassName,
	} = props
	const [open, setOpen] = useState(props.open !== undefined ? props.open : false)
	const { className = '', containerClassName = '' } = props
	const [overlayOpacity, setOverlayOpacity] = useState(0)

	const disableScroll = useCallback((disable: boolean, delayMs: number = 0) => {
		return setTimeout(() => Shout.publish(SHOUTS.DISABLE_SCROLL, { disable }), delayMs)
	}, [])
	disableScroll(open)

	useEffect(() => {
		if (props.toggleRef) {
			props.toggleRef(setOpen)
		}
	}, [props.toggleRef])

	useEffect(() => {
		if (open) {
			setOverlayOpacity(0)
			setTimeout(() => setOverlayOpacity(1), 100)
		} else {
			setOverlayOpacity(0)
		}
	}, [open])

	const onOpen = useCallback(() => {
		!open && setOpen(true)
		disableScroll(true, 250)
		props.onOpen && props.onOpen()
	}, [open, disableScroll, props.onOpen])

	const onClose = useCallback(() => {
		open && setOpen(false)
		disableScroll(false, 250)
		props.onClose && props.onClose()
	}, [open, disableScroll, props.onClose])

	const onClickOutside = useCallback(() => {
		if (autoclose) {
			setOpen(false)
			disableScroll(false, 250)
		}
	}, [autoclose, disableScroll])
	const onClickOutsideRef = useRef(null)
	useOnClickOutside(onClickOutsideRef, onClickOutside)

	return (
		<ReactPopup
			position={'center center'}
			open={open}
			closeOnEscape={autoclose}
			closeOnDocumentClick={false}
			onOpen={onOpen}
			onClose={onClose}
			overlayStyle={{ transition: '0.2s ease', background: popupOverlayColor, opacity: overlayOpacity }}
			contentStyle={{
				border: 'none',
				background: 'none',
				transition: '0.2s ease',
				height,
				minWidth,
				minHeight,
				maxWidth,
				maxHeight,
			}}
			className={Styles.classNames(styles.overlay, containerClassName)}
			children={
				<View ref={onClickOutsideRef} style={{ width }} classNames={[styles.popup, 'shadow-l', innerClassName]}>
					<View ref={onClickOutsideRef} classNames={[styles.inner, className]}>
						{props.children}
					</View>
				</View>
			}
		/>
	)
})

const useOnClickOutside = (ref: any, onClickOutside: (event: any) => void) => {
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside(event)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [ref, onClickOutside])
}
