export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const DAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const DAYS_LOWER = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const MILLIS = {
	ONE_SECOND: 1000,
	ONE_MINUTE: 60 * 1000,
	ONE_HOUR: 60 * 60 * 1000,
	ONE_DAY: 24 * 60 * 60 * 1000,
	ONE_WEEK: 7 * 24 * 60 * 60 * 1000,
	ONE_MONTH: 30 * 24 * 60 * 60 * 1000,
	ONE_YEAR: 365 * 24 * 60 * 60 * 1000,
}

export const RECIPE_INTERVAL_TO_MILLIS = {
	DYNAMIC: 0,
	'1_HOUR': MILLIS.ONE_HOUR,
	'12_HOURS': MILLIS.ONE_HOUR * 12,
	'1_DAY': MILLIS.ONE_DAY,
	'7_DAYS': MILLIS.ONE_DAY * 7,
	'1_MONTH': MILLIS.ONE_MONTH,
}

export const RECIPE_TIMEFRAME_TO_MILLIS = {
	'1_WEEK': MILLIS.ONE_WEEK,
	'2_WEEKS': MILLIS.ONE_WEEK * 2,
	'1_MONTH': MILLIS.ONE_MONTH,
	'6_MONTHS': MILLIS.ONE_MONTH * 6,
	'1_YEAR': MILLIS.ONE_YEAR,
	'2_YEARS': MILLIS.ONE_YEAR * 2,
}
