import React, { memo } from 'react'
import { useColors } from '../../constants'

import { DynamicClassName, DynamicClassNames, View } from '../view'

import styles from '../components.module.sass'

interface DividerProps {
	color?: string
	vertical?: boolean
	thickness?: number
	className?: DynamicClassName
	classNames?: DynamicClassNames
}

export const Divider = memo((props: DividerProps) => {
	const colors = useColors()
	const { color = colors.semilight, vertical = false, thickness = 2, classNames = [] } = props
	return (
		<View
			classNames={[
				styles.divider,
				vertical ? styles.vertical : styles.horizontal,
				props.className,
				...classNames,
			]}
			style={{
				backgroundColor: color,
				height: !vertical ? thickness : undefined,
				width: vertical ? thickness : undefined,
				borderRadius: thickness / 2,
			}}
		/>
	)
})
