import { CreateDoc, DeleteDoc, GetDocById, GetDocBySlug, UpdateDoc } from 'idl'
import { ProtocolFunction } from './util'

export const getDocByIdFunction = ProtocolFunction(GetDocById)

export const getDocBySlugFunction = ProtocolFunction(GetDocBySlug)

export const createDocFunction = ProtocolFunction(CreateDoc)

export const updateDocFunction = ProtocolFunction(UpdateDoc)

export const deleteDocFunction = ProtocolFunction(DeleteDoc)
