import { AuthRequest, Nullable, OptionalAuthRequest, Protocol } from '../common'
import { Doc, DocId, DocPrivacy } from '../models'

/**
 * GetDocById
 */

export interface GetDocByIdRequest extends OptionalAuthRequest {
	id: DocId
}
export interface GetDocByIdResponse {
	doc: Nullable<Doc>
}
export const GetDocById = Protocol<GetDocByIdRequest, GetDocByIdResponse>({
	path: '/docs/getDocById',
	clientCacheMs: 30000,
	authRequired: false,
})

/**
 * GetDocBySlug
 */

export interface GetDocBySlugRequest extends OptionalAuthRequest {
	slug: string
}
export interface GetDocBySlugResponse {
	doc: Nullable<Doc>
}
export const GetDocBySlug = Protocol<GetDocBySlugRequest, GetDocBySlugResponse>({
	path: '/docs/getDocBySlug',
	clientCacheMs: 30000,
	authRequired: false,
})

/**
 * CreateDoc
 */

export interface CreateDocRequest extends AuthRequest {
	slug: string
	body: string
	privacy: DocPrivacy
}
export interface CreateDocResponse {
	id: DocId
	slug: string
}
export const CreateDoc = Protocol<CreateDocRequest, CreateDocResponse>({
	path: '/docs/createDoc',
	invalidatesCacheKeys: [GetDocById.options.cacheKey, GetDocBySlug.options.cacheKey],
})

/**
 * UpdateDoc
 */

export interface UpdateDocRequest extends AuthRequest {
	id: DocId
	slug?: string
	body?: string
	privacy?: DocPrivacy
}
export interface UpdateDocResponse {
	success: boolean
}
export const UpdateDoc = Protocol<UpdateDocRequest, UpdateDocResponse>({
	path: '/docs/updateDoc',
	invalidatesCacheKeys: [GetDocById.options.cacheKey, GetDocBySlug.options.cacheKey],
})

/**
 * DeleteDoc
 */

export interface DeleteDocRequest extends AuthRequest {
	id: DocId
}
export interface DeleteDocResponse {
	success: boolean
}
export const DeleteDoc = Protocol<DeleteDocRequest, DeleteDocResponse>({
	path: '/docs/deleteDoc',
	invalidatesCacheKeys: [GetDocById.options.cacheKey, GetDocBySlug.options.cacheKey],
})
