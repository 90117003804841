import _ from 'lodash'
import { TextInput } from '@mantine/core'
import { DecodedAPIKeys } from 'idl'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserByIdFunction, signInUserFunction } from '../../client/user'
import { Button, Footer, Interactable, PopupToggleFunc, View } from '../../components'
import { Styles, useAuth } from '../../util'

import styles from '../pages.module.sass'

export const OnSignIn = () => {
	const navigate = useNavigate()
	const { auth, userToken } = useAuth()
	const { response: user } = getUserByIdFunction.useQuery({ id: auth?.uid }, undefined, {
		dependencies: [auth],
	})
	const { call: signUpWithToken, isLoading: isSignUpLoading } = signInUserFunction.useMutation()
	const [error, setError] = useState('')
	const [apiKeys, setApiKeys] = useState<DecodedAPIKeys | undefined>(undefined)

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')

	useEffect(() => {
		if (user) {
			navigate('/')
		}
	}, [user])

	const createAcount = useCallback(async () => {
		if (!auth) {
			navigate('/signIn')
			return
		}
		const phoneNumber = auth.phoneNumber
		if (!phoneNumber) return
		signUpWithToken({
			authToken: userToken!,
			phone: phoneNumber,
			displayName: `${firstName} ${lastName}`,
			apiKeys,
		})
			.then(() => {
				navigate('/d')
			})
			.catch((err) => {
				setError(`Error trying to sign in, please try again, ${JSON.stringify(err)}`)
			})
	}, [firstName, lastName, apiKeys, userToken, signUpWithToken])

	const valueDependentStyle = useCallback((...value: any) => {
		return { opacity: _.every(value) ? 1 : 0, height: _.every(value) ? 'auto' : 0 }
	}, [])

	return (
		<View className={styles.addDeviceOuter}>
			<View classNames={[styles.deviceInner, 'container']}>
				<View className={styles.addDeviceText}>Add a Device</View>
				<View className={styles.inputerContainer}>
					<View className={styles.inputerContainer}>
						<View className={styles.inputHeader}>My Info</View>
						<View className={Styles.classNames(styles.helpText, styles.padDown)}>
							Please sign in so we can save your device to your account.
						</View>
						<TextInput
							placeholder="First Name"
							className={Styles.classNames(styles.textInput, styles.padDown)}
							onChange={(value) => setFirstName(value.target.value)}
						/>
						<TextInput
							placeholder="Last Name"
							className={Styles.classNames(styles.textInput, styles.padDown)}
							onChange={(value) => setLastName(value.target.value)}
						/>
					</View>
					<View style={valueDependentStyle(...[firstName, lastName])}>
						<TextInput
							placeholder="DALL·E 2 API Key"
							className={styles.textInput}
							onChange={(value) => setApiKeys({ DALL_E_2: value.target.value })}
						/>
						<View className={styles.helpText}>
							<b>To get a DALL·E 2 API Key (Session Token):</b>
							<ol>
								<li>
									Go to{' '}
									<Interactable
										className={styles.link}
										href={'https://labs.openai.com/'}
										inline
										newTab
									>
										https://labs.openai.com/
									</Interactable>
									.
								</li>
								<li>
									<Interactable
										className={styles.link}
										href={'https://developer.chrome.com/docs/devtools/network/'}
										inline
										newTab
									>
										Open the Network tab
									</Interactable>{' '}
									in Developer Tools.
								</li>
								<li>Type a prompt and press "Generate."</li>
								<li>
									Look for a request to "https://labs.openai.com/api/labs/tasks" and open its request
									body.
								</li>
								<li>
									Find{' '}
									<View className={styles.code} inline>
										Authorization: Bearer SOME_TOKEN
									</View>
									. Copy{' '}
									<View className={styles.code} inline>
										SOME_TOKEN
									</View>{' '}
									and paste it above. (It starts with{' '}
									<View className={styles.code} inline>
										sess-
									</View>
									).
								</li>
							</ol>
						</View>
					</View>
				</View>
				<View>
					<View className={styles.addDeviceButtonOuter}>
						<Button
							className={styles.signUpAndAddDevice}
							text={'Sign Up'}
							loading={isSignUpLoading}
							disabled={!firstName || !lastName}
							onClick={createAcount}
						/>
						{error && <View className={styles.sinupError}>{error}</View>}
					</View>
					<View id="recaptcha-container"></View>
					<View className={styles.termsAndConditions}>
						By signing up, you hereby agree to the Privacy Policy and Terms.
					</View>
				</View>
			</View>
			<Footer />
		</View>
	)
}
