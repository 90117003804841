import { Playlist, PlaylistWithQueue } from 'idl'
import React, { useMemo } from 'react'
import QRCode from 'react-qr-code'
import { useWindowSize } from 'use-hooks'
import { DynamicClassName, DynamicClassNames, View } from '../view'

import styles from '../components.module.sass'

const EMPTY_ARRAY = []

interface PlaylistQRProps {
	className?: DynamicClassName
	classNames?: DynamicClassNames
	playlist: Playlist | PlaylistWithQueue
	size?: string | number
	dependencies?: unknown[]
}

export const PlaylistQR = ({
	className,
	classNames = EMPTY_ARRAY,
	playlist,
	size = 200,
	dependencies = EMPTY_ARRAY,
}: PlaylistQRProps) => {
	const url = `${window.location.origin}/d/${playlist.slug}`
	const { width: playlistWidth, height: playlistHeight } = useWindowSize()

	const qrSize = useMemo(() => {
		if (typeof size === 'number') {
			return size
		}
		size = size.toLowerCase()
		if (size.endsWith('%') || size.endsWith('vw')) {
			return playlistWidth * (parseFloat(size) / 100)
		} else if (size.endsWith('vh')) {
			return playlistHeight * (parseFloat(size) / 100)
		}
	}, [size, playlistWidth, playlistHeight, ...dependencies])

	return (
		<View classNames={[styles.qrOuter, className, ...classNames]}>
			<QRCode size={qrSize} className={styles.qr} value={url} />
		</View>
	)
}
