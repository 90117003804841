import { DatabaseModel, PlaylistId } from '.'
import { Id } from '../common'
import { UserId } from './User'

export interface Graphic extends DatabaseModel {
	id: GraphicId
	title: string
	author?: UserId
	authorName: string
	imageUrls: UnsignedGraphicUrl[]
	currentIndex: number
	likes: UserId[]
	status: GraphicStatus
	error?: string
	__originalPlaylist: PlaylistId
	__isDeleted: boolean
	/** If true, the Graphic has been displayed at least once. */
	__wasDisplayed: boolean
}

export type GraphicId = Id

export type GraphicStatus = 'prompted' | 'generating' | 'success' | 'failure'

export type UnsignedGraphicUrl = string
export type SignedGraphicUrl = string

export type QueueDirection = 'next' | 'previous' | 'start' | 'end' | 'pause' | 'play'
export const queueDirections: QueueDirection[] = ['next', 'previous', 'start', 'end', 'pause', 'play']

export type QueuePosition = 'current' | 'next' | 'end'
export const queuePositions: QueuePosition[] = ['current', 'next', 'end']
