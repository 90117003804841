import React, { memo } from 'react'
import { useColors, viewports } from '../../../constants'
import { View } from '../../view'
import { useWindowSize } from 'use-hooks'
import { Popup, PopupToggleFunc } from '../Popup'
import { Button } from '../..'

import styles from '../../components.module.sass'

interface ConfirmDeletePopupProps {
	toggleRef: (toggler: PopupToggleFunc) => void
	deleteText: string
	onSubmit: () => void
	onCancel: () => void
	isLoading: boolean
}

export const ConfirmDeletePopup = memo((props: ConfirmDeletePopupProps) => {
	const { toggleRef, isLoading, deleteText } = props
	const { width } = useWindowSize()
	const colors = useColors()

	let popupToggleFunc: PopupToggleFunc = null

	const onSubmit = () => {
		popupToggleFunc && popupToggleFunc(false)
		props.onSubmit()
	}

	const onCancel = () => {
		popupToggleFunc && popupToggleFunc(false)
		props.onCancel()
	}

	return (
		<Popup
			open={false}
			toggleRef={(t) => {
				if (toggleRef) {
					toggleRef(t)
					popupToggleFunc = t
				}
			}}
			width={width < viewports.desktop ? '80vw' : '30vw'}
			className={styles.confirmDeletePopupOuter}
		>
			<View className={styles.popupContainer}>
				<View className={styles.inputHeader}>
					Are you sure you want to delete <span style={{ color: colors.primary }}>{deleteText}</span>?
				</View>
				<View className={styles.buttonContainer}>
					<Button text={'Cancel'} onClick={onCancel} className={styles.confirmButton} color={colors.dark} />
					<Button text={'Delete'} onClick={onSubmit} className={styles.confirmButton} loading={isLoading} />
				</View>
			</View>
		</Popup>
	)
})
