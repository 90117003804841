import { APIProvider } from '../../models'

const API_KEY__DISPLAY_NAME = 'API Key'
const API_KEY__VARIABLE_NAME = 'apiKey'

export interface ModelCredentialKey {
	displayName: string
	variableName: string
}

export interface Model {
	provider: APIProvider
	displayName: string
	url: string
	description: string
	credentials: ModelCredentialKey[] | ModelCredentialKey | null
	docsUrl: string
}

export type ModelCredentials = string | Record<string, string> | null

export interface ModelWithCredentials extends Omit<Model, 'credentials'> {
	credentials: ModelCredentials
}

export const DallE2Model: Model = {
	provider: APIProvider.DALL_E_2,
	displayName: 'Dall·E 2',
	url: 'https://openai.com/dall-e-2/',
	description: 'Created by OpenAI. Generates realistic images and art from a description.',
	credentials: {
		displayName: API_KEY__DISPLAY_NAME,
		variableName: API_KEY__VARIABLE_NAME,
	},
	docsUrl: '/docs/dall-e-2',
}

export const StableDiffusionModel: Model = {
	provider: APIProvider.LEXICA_ART,
	displayName: 'Stable Diffusion',
	url: 'https://stability.ai/blog/stable-diffusion-public-release',
	description: 'Created by StabilityAI. Generates highly detailed artistic images and paintings from a description.',
	credentials: null,
	docsUrl: '/docs/stable-diffusion',
}

export const apiProviderToModel = {
	[APIProvider.LEXICA_ART]: StableDiffusionModel,
	[APIProvider.DALL_E_2]: DallE2Model,
}

export const models = Object.values(apiProviderToModel)
