import { DatabaseModel, EncodedAPIKeys } from '.'
import { Id } from '../common'

export interface User extends DatabaseModel {
	id: UserId
	displayName: string
	phone: string
	role: UserRole
	__ip: string
	__geohash?: string
	__apiKeys: EncodedAPIKeys
}

export interface UserAgentInfo {
	/** The full user agent. */
	full?: string
	/** The name of the user agent (ex. Chrome). */
	name?: string
	/** The version string of the user agent. */
	version?: string
	/** The device of the user agent. */
	device?: string
	/** The operating system of the user agent. */
	os?: string
}

export type UserRole = 'BASIC' | 'ADMIN'
export const userRoles: UserRole[] = ['BASIC', 'ADMIN']

export type UserId = Id
export type UserToken = string

export type FirebaseAuthToken = string
