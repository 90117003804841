import { APIProvider, DatabaseModel } from '.'
import { Id } from '../common'
import { Graphic, GraphicId } from './Graphic'
import { UserId } from './User'

export interface Playlist extends DatabaseModel {
	id: PlaylistId
	slug: string
	owner: UserId
	privacy: PlaylistPrivacy
	name: string
	autoSwitchMins: number | null
	provider: APIProvider
	queue: GraphicId[]
	timestamp: number
	isPaused: boolean
	permissions: PlaylistPermissions
	settings: PlaylistSettings
	__passcode: string | null
	__ip: string
	__geohash?: string
	__isDeleted: boolean
}

export interface PlaylistWithQueue extends Omit<Playlist, 'queue'> {
	queue: Graphic[]
	current?: Graphic
}

export type PlaylistPermissionLevel = 'OWNER' | 'ALL'
export interface PlaylistPermissions {
	/** Who can control the current image, move back and forth, etc? */
	control: PlaylistPermissionLevel
}
export const playlistPermissionsKeys: (keyof PlaylistPermissions)[] = ['control']

export interface PlaylistSettings {
	/** If true, hides the prompt when displaying in full screen mode. */
	hidePromptOnDisplay?: boolean
	/** If true, obfuscates the image until it is first displayed. */
	hideBeforeFirstDisplay?: boolean
}
export const playlistSettingsKeys: (keyof PlaylistSettings)[] = ['hidePromptOnDisplay', 'hideBeforeFirstDisplay']

export type PlaylistPrivacy = 'PUBLIC' | 'PRIVATE'
export const playlistPrivacies: PlaylistPrivacy[] = ['PUBLIC', 'PRIVATE']

export type PlaylistId = Id
