import { ProtocolFunctionInterface } from 'idl'
import { cancelable, CancelablePromise } from 'cancelable-promise'
import fetch from 'cross-fetch'
import { API_URL } from '../util'
import _ from 'lodash'

export type ClientStatusCallback = (res: Response) => Promise<void>
export type ClientStatusCallbacks = { [status: number]: ClientStatusCallback }

const statusCallbacks = {} as ClientStatusCallbacks
export const client = (...args: Parameters<Client['v0Call']>) => new Client(API_URL(), statusCallbacks).v0Call(...args)
export const v1Client = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
	body: RequestType,
) => new Client(API_URL(), statusCallbacks).call(protocol, body)

export const v1ClientFallback = <RequestType, ResponseType>(
	protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
	body: RequestType,
	defaultValue?: ResponseType,
): CancelablePromise<ResponseType> => {
	try {
		return v1Client(protocol, body)
	} catch (e) {
		if (defaultValue) {
			return cancelable((async () => defaultValue)())
		}
		throw e
	}
}

export class Client {
	private apiUrl: string
	private statusCallbacks: ClientStatusCallbacks

	constructor(apiUrl: string, statusCallbacks: ClientStatusCallbacks = {}) {
		if (apiUrl.lastIndexOf('/') === apiUrl.length - 1) {
			apiUrl = apiUrl.substring(0, apiUrl.length - 1)
		}
		this.apiUrl = apiUrl
		this.statusCallbacks = statusCallbacks
	}

	/**
	 * Calls a protocol function, returning its response body or propagating the error.
	 * @param protocol The protocol function to call.
	 * @param body The body of the request, usually an object.
	 * @returns Asynchronously returns a response with a cancelable promise.
	 * @throws An error coming from the API.
	 */
	public call = <RequestType, ResponseType>(
		protocol: ProtocolFunctionInterface<RequestType, ResponseType>,
		body: RequestType,
	): CancelablePromise<ResponseType> => {
		const options = protocol.options
		if (options.path[0] !== '/') {
			throw Error('Protocol path must start with a slash')
		}

		const uri = this.getUri(options.path)

		const getResponse = async (): Promise<any> => {
			const isObjectBody = _.isObject(body)
			const res = await this.fetchWithTimeout(
				uri,
				{
					method: options.method,
					body: isObjectBody ? JSON.stringify(body) : (body as any),
					headers: {
						'Content-Type': isObjectBody ? 'application/json' : 'text/plain',
					},
				},
				options.clientTimeoutMs,
			)
			return this.parseResponse(res)
		}

		return cancelable(getResponse())
	}

	/**
	 * [Deprecated] Calls an API endpoint and returns the result as JSON or text (or null), depending on what it is.
	 * @param endpoint The endpoint to call, starting with a /.
	 * @param body The body of the request, usually an object.
	 * @returns Asynchronously returns a response.
	 * @throws An error coming from the API.
	 */
	public v0Call = async <T extends (...args: any) => any>(endpoint: string, body: any): Promise<Parameters<T>[0]> => {
		if (endpoint.indexOf('/') !== 0) {
			throw new Error('client Error: endpoint must start with a forward slash')
		}
		const uri = this.getUri(endpoint)
		const getResponse = async () => {
			try {
				const res = await fetch(uri, {
					method: 'POST',
					body: _.isObject(body) ? JSON.stringify(body) : body,
					headers: {
						'Content-Type': _.isObject(body) ? 'application/json' : 'text/plain',
					},
				})
				return this.parseResponse(res)
			} catch (e) {
				throw new Error(`API Error: ${(e as Error).toString()}`)
			}
		}
		return getResponse()
	}

	/**
	 * Creates a URI from the endpoint and API URL.
	 * @param endpoint The endpoint to append.
	 * @returns A string URI to the given endpoint.
	 */
	private getUri = (endpoint: string): string => this.apiUrl + endpoint

	private parseResponse = async (res: Response) => {
		const resText = await res.text()
		if (_.has(this.statusCallbacks, res.status)) {
			await this.statusCallbacks[res.status](res)
		}
		if (!res.ok) {
			throw new Error(resText)
		}
		// Return null if the response is null
		if (resText === 'null') {
			return null
		}
		// Return JSON if the response is JSON
		try {
			const resJson = JSON.parse(resText)
			if (_.isObject(resJson)) {
				return resJson
			}
		} catch {}
		// Return text if the response is text
		return resText
	}

	/**
	 * Adds an optional timeout to a fetch.
	 * @param uri The URI to fetch.
	 * @param options Options to pass to the request.
	 * @param timeoutMs The timeout in milliseconds.
	 * @returns A Response promise.
	 */
	private fetchWithTimeout = (uri: string, options: RequestInit, timeoutMs?: number) => {
		if (!timeoutMs) {
			return fetch(uri, options)
		}
		const controller = new AbortController()
		setTimeout(() => controller.abort(), timeoutMs)
		return fetch(uri, { signal: controller.signal, ...options })
	}
}
