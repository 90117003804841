import React from 'react'
import { BrowserRouter as Router, Route as ReactRoute, Routes } from 'react-router-dom'
import { functionClient, FunctionClientProvider } from './client/util'
import { Route } from './models'

import { AddPlaylist, Playlist, PlaylistView, Docs, Home, NotFound, OnSignIn, SignIn } from './pages'
import { AuthProvider } from './util'

const ROUTES: Route[] = [
	{
		name: 'PlaylistView',
		path: '/v/:slug',
		page: <PlaylistView />,
		auth: 'BOTH',
		exact: true,
	},
	{
		name: 'Playlist',
		path: '/d/:slug',
		page: <Playlist />,
		auth: 'BOTH',
		exact: true,
	},
	{
		name: 'AddPlaylist',
		path: '/d',
		page: <AddPlaylist />,
		auth: 'BOTH',
		exact: true,
	},
	{
		name: 'Docs',
		path: '/docs/:slug',
		page: <Docs />,
		auth: 'BOTH',
		exact: true,
	},
	{
		name: 'Sign In',
		path: '/signIn',
		page: <SignIn />,
		auth: 'NOT_LOGGED_IN',
		exact: true,
	},
	{
		name: 'On Sign In',
		path: '/onSignIn',
		page: <OnSignIn />,
		auth: 'LOGGED_IN',
		exact: true,
	},
	{
		name: 'Home',
		path: '/',
		page: <Home />,
		auth: 'BOTH',
		exact: true,
	},
	{
		name: 'Not Found',
		path: '*',
		page: <NotFound />,
		auth: 'BOTH',
		exact: true,
	},
]

export const App = () => {
	return (
		<FunctionClientProvider client={functionClient}>
			<AuthProvider>
				<Router>
					<Routes>
						{ROUTES.map((route) => (
							<ReactRoute key={route.name} path={route.path} element={route.page} />
						))}
					</Routes>
				</Router>
			</AuthProvider>
		</FunctionClientProvider>
	)
}
