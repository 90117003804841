import { Graphic as GraphicModel, PlaylistWithQueue } from 'idl'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import ReactTimeAgo from 'react-time-ago'
import { updatePlaylistFunction } from '../../client/playlists'
import { ConfirmDeletePopup, Loading, PopupToggleFunc, View } from '../../components'
import { SIZE, useColors } from '../../constants'
import { useAuth } from '../../util'
import Drag from '../icons/Drag'
import { Graphic } from '../visuals'

import styles from '../components.module.sass'

const EMPTY_QUEUE = []

export const Queue: React.FC<{
	queue: GraphicModel[] | undefined
	setQueue: Dispatch<SetStateAction<GraphicModel[]>>
	playlist: PlaylistWithQueue
	containerClassName?: string
	canControl: boolean
}> = ({ queue = EMPTY_QUEUE, setQueue, playlist, canControl, containerClassName }) => {
	const { call: updatePlaylist, isLoading: isUpdatingPlaylist } = updatePlaylistFunction.useMutation()
	const { userToken } = useAuth()

	const removeGraphicFromPlaylist = useCallback(
		async (graphicId: string) => {
			if (userToken) {
				const copyQueue = [...queue]
				const newQueue = copyQueue.filter((graphic) => graphic.id !== graphicId)
				await updatePlaylist({ userToken, id: playlist.id, queue: newQueue.map((graphic) => graphic.id) })
			}
		},
		[userToken, queue, playlist, updatePlaylist],
	)

	const draggableItems = useMemo(
		() =>
			queue.map((graphic, index) => {
				let confirmDeleteRef: PopupToggleFunc
				const toggleConfirmDeletePopup = (open: boolean) => {
					confirmDeleteRef && confirmDeleteRef(open)
				}
				return (
					<Draggable key={graphic.id} index={index} draggableId={graphic.id}>
						{(provided) => (
							<View ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
								<QueueItem
									key={graphic.id}
									graphic={graphic}
									index={index}
									canControl={canControl}
									toggleDeletePopup={toggleConfirmDeletePopup}
								/>
								<ConfirmDeletePopup
									toggleRef={(t) => (confirmDeleteRef = t)}
									onSubmit={async () => {
										await removeGraphicFromPlaylist(graphic.id)
										toggleConfirmDeletePopup(false)
									}}
									onCancel={() => toggleConfirmDeletePopup(false)}
									deleteText={graphic.title}
									isLoading={isUpdatingPlaylist}
								/>
							</View>
						)}
					</Draggable>
				)
			}),
		[queue, playlist],
	)

	const onDragEnd = useCallback(
		({ destination, source }: DropResult) => {
			if (destination === undefined || destination === null) {
				// Remove item on drag out
				const newQueue = [...queue]
				newQueue.splice(source.index, 1)
				setQueue(newQueue)
				userToken &&
					updatePlaylist({ userToken, id: playlist.id, queue: newQueue.map((graphic) => graphic.id) })
				return null
			}
			if (destination.index === source.index) return null
			if (destination.index === source.index) return null

			const newQueue = [...queue]
			const [removed] = newQueue.splice(source.index, 1)
			newQueue.splice(destination.index, 0, removed)
			setQueue(newQueue)

			userToken && updatePlaylist({ userToken, id: playlist.id, queue: newQueue.map((graphic) => graphic.id) })
		},
		[userToken, queue, playlist],
	)

	return (
		<View classNames={[styles.queueOuter, containerClassName]}>
			<View className={styles.queueTitle}>Next Up</View>
			<View className={styles.queueInner}>
				{queue.length ? (
					canControl ? (
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="dnd-list" direction="vertical">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										<>
											{draggableItems}
											{provided.placeholder}
										</>
									</div>
								)}
							</Droppable>
						</DragDropContext>
					) : (
						queue.map((graphic, index) => (
							<QueueItem key={graphic.id} graphic={graphic} index={index} canControl={canControl} />
						))
					)
				) : (
					<View className={styles.queueItem}>
						<View className={styles.graphicInfo}>
							<View className={styles.queueItemTitle}>No Items in queue</View>
						</View>
					</View>
				)}
			</View>
		</View>
	)
}

const QueueItem: React.FC<{
	graphic: GraphicModel
	index: number
	canControl: boolean
	toggleDeletePopup?: (open: boolean) => void
}> = ({ graphic, index, canControl, toggleDeletePopup }) => {
	const colors = useColors()
	const imageUrl = graphic.imageUrls?.[graphic.currentIndex]
	const isGenerating = graphic.status === 'generating' || graphic.status === 'prompted'
	return (
		<View
			classNames={[styles.queueItem, canControl && styles.owner, index === 0 && styles.isSelected]}
			unselectable
		>
			<View classNames={[styles.queueItemIdx, isGenerating && styles.generating]}>{index + 1}</View>
			<View classNames={[styles.graphicInfo, isGenerating && styles.generating]}>
				{graphic.status === 'prompted' || graphic.status === 'generating' ? (
					<Loading size={30} />
				) : graphic.status === 'success' ? (
					<Graphic src={imageUrl} alt={graphic.title} className={styles.image} />
				) : (
					'Error'
				)}
			</View>
			<View classNames={[styles.queueItemTitle, isGenerating && styles.generating]}>{graphic.title}</View>
			<View classNames={[styles.createdInfo, isGenerating && styles.generating]}>
				<View className={styles.authorName}>{graphic.authorName}</View>
				{graphic.createdAt && (
					<View className={styles.createdAt}>
						<ReactTimeAgo date={new Date(graphic.createdAt!)} locale={'en-US'} />
					</View>
				)}
			</View>
			{canControl && (
				<View className={styles.dragItemContainer}>
					<Drag fill={colors.medium} size={SIZE.M2} />
				</View>
			)}
		</View>
	)
}
