import { Grid, Select, TextInput } from '@mantine/core'
import {
	apiProviderToModel,
	GetPlaylistBySlugRequest,
	GetPlaylistBySlugResponse,
	ModelWithCredentials,
	QueueDirection,
} from 'idl'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import { PLACEHOLDER } from '../../assets/images'
import { getUserByIdFunction, updateGraphicIndexFunction } from '../../client'
import { Progress } from '@mantine/core'
import {
	deletePlaylistFunction,
	progressQueueFunction,
	queueGraphicFunction,
	updatePlaylistFunction,
	watchPlaylist,
} from '../../client/playlists'
import { subscripeToProtocolFunction as subscribeToProtocolFunction } from '../../client/util'
import {
	Button,
	ConfirmDeletePopup,
	Divider,
	Footer,
	Interactable,
	Loading,
	ModelIcon,
	ModelPicker,
	PopupToggleFunc,
	Queue,
	View,
} from '../../components'
import { Graphic } from '../../components/visuals'
import { useColors } from '../../constants'
import { Session, useAuth, zeroPad } from '../../util'
import { slideShowSpeedData } from '../addPlaylist/AddPlaylist'
import Previous from '../../components/icons/Previous'
import Pause from '../../components/icons/Pause'
import Play from '../../components/icons/Play'
import Next from '../../components/icons/Next'

import styles from '../pages.module.sass'

const useSecondsElapsed = (
	timestamp?: number,
	autoSwitchMins?: number | null,
	paused: boolean = false,
): [number, string, number, string] => {
	const [secondsElapsed, setSecondsElapsed] = useState(0)
	const [totalSeconds, setTotalSeconds] = useState((autoSwitchMins ?? 0) * 60)

	useEffect(() => {
		const interval = setInterval(() => {
			if (paused) {
				return setSecondsElapsed(0)
			} else if (timestamp && autoSwitchMins) {
				const totalSeconds = autoSwitchMins * 60
				const switchTimestampSec = Math.floor(timestamp / (1000 * 60)) * 60
				const seconds = Math.round(Math.floor(Date.now() / 1000) - switchTimestampSec) % totalSeconds
				setSecondsElapsed(seconds)
				setTotalSeconds(totalSeconds)
			}
		}, 1000)
		return () => clearInterval(interval)
	}, [timestamp, autoSwitchMins, paused])

	return [
		secondsElapsed,
		paused ? '––:––' : `${zeroPad(Math.floor(secondsElapsed / 60), 2)}:${zeroPad(secondsElapsed % 60, 2)}`,
		totalSeconds,
		`${zeroPad(Math.floor(totalSeconds / 60), 2)}:${zeroPad(totalSeconds % 60, 2)}`,
	]
}

export const Playlist = () => {
	const { slug } = useParams() as { slug: string }
	const { auth, userToken } = useAuth()
	const colors = useColors()
	const navigate = useNavigate()

	const [prompt, setPrompt] = useState('')
	const [authorName, setAuthorName] = Session.use('author-name', '')
	const [passcode, setPasscode] = Session.use(`${slug}.passcode`, '')

	const { call: queueGraphic, isLoading, error } = queueGraphicFunction.useMutation()
	const [playlistDoesntExistError, setPlaylistDoesntExistError] = useState(false)

	const { data: response, isLoading: isLoadingPlaylist } = subscribeToProtocolFunction<
		GetPlaylistBySlugRequest,
		GetPlaylistBySlugResponse
	>(watchPlaylist, { slug }, [slug])
	const playlist = useMemo(() => response?.playlist, [response])
	const [queue, setQueue] = useState(playlist?.queue ?? [])
	useEffect(() => {
		setQueue(playlist?.queue ?? [])
	}, [playlist?.queue])

	const [secondsElapsed, secondsElapsedString, totalSeconds, totalSecondsString] = useSecondsElapsed(
		playlist?.timestamp,
		playlist?.autoSwitchMins,
		playlist?.isPaused,
	)

	const [currentGraphic, setCurrentGraphic] = useState(playlist?.current)
	useEffect(() => setCurrentGraphic(playlist?.current), [playlist?.current])

	const currentImage = currentGraphic?.imageUrls[currentGraphic?.currentIndex]
	const variants =
		currentGraphic?.imageUrls && currentGraphic?.currentIndex !== undefined
			? currentGraphic.imageUrls.map((image, i) => [i, image] as [number, string])
			: []

	// Updating playlist states
	const [slideShowSpeed, setSlideShowSpeed] = useState<number | null>(5)
	const [isPrivate, setIsPrivate] = useState(false)
	const [updatePlaylistError, setUpdatePlaylistError] = useState(false)
	const [newPasscode, setNewPasscode] = useState<string>('')
	const [newPlaylistName, setNewPlaylistName] = useState<string | undefined>()
	const [playlistNameError, setPlaylistNameError] = useState(false)
	const [areValidCredentials, setAreValidCredentials] = useState(false)
	const [newModel, setNewModel] = useState<ModelWithCredentials | null>(null)
	useEffect(() => {
		if (playlist) {
			setSlideShowSpeed(playlist.autoSwitchMins)
			setIsPrivate(playlist.privacy === 'PRIVATE')
			setUpdatePlaylistError(false)
			setNewPasscode(playlist.__passcode ?? '')
			setNewPlaylistName(playlist.name)
			setPlaylistNameError(false)
			setNewModel(apiProviderToModel[playlist.provider] as any)
		}
	}, [playlist])

	const {
		call: updatePlaylist,
		isLoading: isUpdatingPlaylist,
		error: errorUpdatingPlaylist,
	} = updatePlaylistFunction.useMutation()
	const {
		call: deletePlaylist,
		isLoading: isDeletingPlaylist,
		error: errorDeletingPlaylist,
	} = deletePlaylistFunction.useMutation()

	const onAddPromptToQueue = () => {
		if (playlist) {
			queueGraphic({
				id: playlist.id,
				title: prompt,
				author: auth?.uid ?? '',
				authorName: authorName!,
				passcode: passcode!,
			}).then(() => {
				setPrompt('')
				setAuthorName('')
			})
		} else {
			setPlaylistDoesntExistError(true)
		}
	}
	useEffect(() => {
		if (playlist) {
			setPlaylistDoesntExistError(false)
		}
	}, [playlist])

	useEffect(() => {
		if (newPlaylistName === '') {
			setNewPlaylistName(undefined)
			setPlaylistNameError(false)
		} else if (newPlaylistName && playlistNameError && newPlaylistName.length >= 4) {
			setPlaylistNameError(false)
		}
	}, [newPlaylistName, playlistNameError])

	const onSpeedChange = useCallback((value: string) => {
		const speedMin = slideShowSpeedData.find((d) => d.label === value)?.value || null
		setSlideShowSpeed(speedMin)
	}, [])

	const canEdit = Boolean(playlist && auth && playlist.owner === auth.uid)

	const canControl =
		canEdit ||
		Boolean(
			auth &&
				playlist &&
				playlist.permissions.control === 'ALL' &&
				(playlist.privacy === 'PUBLIC' || (playlist.privacy === 'PRIVATE' && passcode)),
		)

	const onUpdatePlaylist = useCallback(() => {
		if (newPlaylistName !== undefined && newPlaylistName.length < 4) {
			setPlaylistNameError(true)
			return
		}
		if (playlist && userToken) {
			setUpdatePlaylistError(false)
			updatePlaylist({
				userToken,
				id: playlist.id,
				privacy: isPrivate ? 'PRIVATE' : 'PUBLIC',
				passcode: isPrivate ? newPasscode : undefined,
				name: newPlaylistName,
				autoSwitchMins: slideShowSpeed,
			})
		} else {
			setUpdatePlaylistError(true)
		}
	}, [playlist, isPrivate, slideShowSpeed, userToken, newPasscode, newPlaylistName])
	const onDeletePlaylist = useCallback(() => {
		if (playlist && userToken) {
			setUpdatePlaylistError(false)
			deletePlaylist({ userToken, id: playlist.id }).then((res) => {
				navigate('/')
			})
		} else {
			setUpdatePlaylistError(true)
		}
	}, [playlist, userToken, navigate])

	const onUpdateGraphicIndex = useCallback(
		async (currentIndex: number) => {
			if (currentGraphic && userToken) {
				await updateGraphicIndexFunction.call({
					userToken,
					id: currentGraphic.id,
					currentIndex,
				})
				const newCurrentGraphic = _.clone(currentGraphic)
				newCurrentGraphic.currentIndex = currentIndex
				setCurrentGraphic(newCurrentGraphic)
			}
		},
		[currentGraphic, userToken],
	)

	let confirmDeleteRef: PopupToggleFunc
	const toggleConfirmDeletePopup = (open: boolean) => {
		confirmDeleteRef && confirmDeleteRef(open)
	}

	const [isControlling, setIsControlling] = useState(false)
	const handleControl = useCallback(
		async (direction: QueueDirection) => {
			if (userToken && playlist) {
				setIsControlling(true)
				try {
					await progressQueueFunction.call({
						userToken,
						id: playlist.id,
						direction,
						passcode: passcode || undefined,
					})
				} catch {}
				setIsControlling(false)
			}
		},
		[userToken, playlist, passcode],
	)

	const creator = getUserByIdFunction.useCall(
		{
			userToken,
			id: playlist?.owner,
		},
		undefined,
		{
			dependencies: [playlist?.owner],
			enabled: [playlist?.owner],
		},
	)

	return (
		<View className={styles.playlistOuter}>
			<View classNames={[styles.playlistInner, 'container']}>
				<View className={styles.welcomeText}>{isLoadingPlaylist ? 'Loading...' : playlist?.name}</View>
				{playlist && (
					<>
						<View className={styles.playlistInfoContainer} unselectable>
							{creator && (
								<View classNames={[styles.playlistInfoItem, styles.owner]}>
									<View className={styles.playlistInfoText}>By {creator.displayName}</View>
								</View>
							)}
							{newModel && (
								<Interactable
									classNames={[styles.playlistInfoItem, styles.model]}
									href={newModel.url}
									newTab
								>
									<ModelIcon provider={newModel.provider} size={20} fill={colors.white} />
									<View className={styles.playlistInfoText}>{newModel.displayName}</View>
								</Interactable>
							)}
							<View className={styles.playlistInfoItem}>
								<View className={styles.playlistInfoText}>
									{playlist.privacy === 'PRIVATE' ? 'Private' : 'Public'}
								</View>
							</View>
							{playlist.autoSwitchMins && (
								<View className={styles.playlistInfoItem}>
									<View className={styles.playlistInfoText}>
										{playlist.autoSwitchMins} {playlist.autoSwitchMins === 1 ? 'min' : 'mins'}
									</View>
								</View>
							)}
						</View>
						<View className={styles.displayURLOuter}>
							<View className={styles.displayURLHeader}>👁 Display URL</View>
							<Interactable
								className={styles.displayURL}
								href={`/v/${playlist?.slug}${
									playlist?.privacy === 'PRIVATE' ? `?p=${playlist?.__passcode}` : ''
								}`}
								inline
								newTab
							>
								dallefy.com/v/{playlist?.slug}
								{playlist?.privacy === 'PRIVATE' ? `?p=${playlist?.__passcode}` : ''}
							</Interactable>
						</View>
					</>
				)}
				{playlist && <View className={styles.currentArtwork}>🖼 Current Artwork</View>}
				<View className={styles.imageContainer}>
					<Graphic src={currentImage ?? PLACEHOLDER} alt={'Current Artwork'} classNames={[styles.image]} />
				</View>
				<View className={styles.artworkTitle}>{currentGraphic?.title}</View>
				{currentGraphic?.authorName && (
					<View className={styles.authorInfo}>
						<View className={styles.authorText}>
							Drawn by <b className={styles.author}>{currentGraphic?.authorName}</b>
						</View>
						{currentGraphic && (
							<View className={styles.timeAgo}>
								<ReactTimeAgo date={new Date(currentGraphic?.createdAt!)} locale={'en-US'} />
							</View>
						)}
					</View>
				)}
				{playlist && (
					<View className={styles.controls}>
						{playlist.autoSwitchMins && (
							<View className={styles.bar} style={{ opacity: playlist.isPaused ? 0.6 : 1 }}>
								<View className={styles.text}>{secondsElapsedString}</View>
								<Progress
									value={(secondsElapsed / totalSeconds) * 100}
									color={colors.primary}
									className={styles.progress}
								/>
								<View className={styles.text}>{totalSecondsString}</View>
							</View>
						)}
						<View className={styles.buttons}>
							<Interactable
								disabled={!canControl || isControlling}
								onClick={() => handleControl('previous')}
								className={styles.small}
							>
								<Previous fill={colors.white} size={50} />
							</Interactable>
							<Interactable
								disabled={!canControl || isControlling}
								onClick={() => handleControl(playlist.isPaused ? 'play' : 'pause')}
								className={styles.large}
							>
								{playlist.isPaused ? (
									<Play fill={colors.white} size={60} />
								) : (
									<Pause fill={colors.white} size={60} />
								)}
							</Interactable>
							<Interactable
								disabled={!canControl || isControlling}
								onClick={() => handleControl('next')}
								className={styles.small}
							>
								<Next fill={colors.white} size={50} />
							</Interactable>
						</View>
						{!canControl && (
							<View className={styles.disclaimer} unselectable>
								Only the playlist creator can control it.
							</View>
						)}
					</View>
				)}
				{playlistDoesntExistError && <View className={styles.addToQueueError}>Error loading playlist</View>}
				{canControl && variants.length > 0 && (
					<View className={styles.variantsContainer}>
						<View className={styles.otherVariants}>Other Variants</View>
						<View className={styles.hint}>Select a variant to change the current artwork.</View>
						<View className={styles.variants}>
							{variants.map(([index, image]) => (
								<Interactable
									key={image}
									onClick={() => onUpdateGraphicIndex(index)}
									className={styles.variant}
									disabled={index === currentGraphic?.currentIndex}
								>
									<Graphic
										src={image ?? PLACEHOLDER}
										alt={`Variant ${index + 1}`}
										className={styles.image}
									/>
								</Interactable>
							))}
						</View>
					</View>
				)}
				{playlist ? (
					<Queue
						queue={queue}
						setQueue={setQueue}
						playlist={playlist}
						canControl={!!canControl}
						containerClassName={styles.queueContainer}
					/>
				) : (
					<View className={styles.loadingContainer}>
						<Loading />
					</View>
				)}
				{playlist && (
					<View className={styles.inputerContainer}>
						<View className={styles.requestArtwork}>🤖 Generate &amp; Queue Up Art</View>
						<View classNames={[styles.searchContainer]}>
							<TextInput
								placeholder="Describe your work of art"
								onChange={(value) => setPrompt(value.target.value)}
								className={styles.search}
								value={prompt}
							/>
							<TextInput
								placeholder="Your name"
								onChange={(value) => setAuthorName(value.target.value)}
								className={styles.searchSmall}
								value={authorName ?? undefined}
							/>
						</View>
						<View classNames={[styles.searchContainer, styles.inputerContainerSmall]}>
							{playlist?.privacy === 'PRIVATE' && (
								<TextInput
									placeholder="Passcode"
									onChange={(value) => setPasscode(value.target.value)}
									className={styles.search}
								/>
							)}
							<Button
								onClick={onAddPromptToQueue}
								text={'Add to Queue'}
								loading={isLoading}
								containerClassName={styles.addToQueueButtonContainer}
								className={styles.addToQueueButton}
							/>
						</View>
					</View>
				)}
				{error && <View className={styles.addToQueueError}>{error.message}</View>}
				{canEdit && (
					<>
						<Divider color={colors.light} className={styles.divider} />
						<View className={styles.welcomeText}>Edit Playlist</View>
						<View className={styles.inputerContainer}>
							<View className={styles.inputHeader}>Playlist Name</View>
							<TextInput
								value={newPlaylistName}
								placeholder={playlist?.name}
								className={styles.textInput}
								onChange={(value) => setNewPlaylistName(value.target.value)}
							/>
						</View>
						<View className={styles.inputerContainer}>
							<View className={styles.inputHeader}>Choose an AI</View>
							<ModelPicker
								onChangeSelected={setNewModel}
								setAreValidCredentials={setAreValidCredentials}
								encodedCredentials={creator?.__apiKeys}
								selectedModel={newModel?.provider}
							/>
						</View>
						<View className={styles.inputerContainer}>
							<View className={styles.inputHeader}>Slides Rotate...</View>
							<Select
								style={{ zIndex: 2 }}
								data={slideShowSpeedData.map((item) => item.label)}
								onChange={onSpeedChange}
								value={
									slideShowSpeedData.find((d) => d.value === slideShowSpeed)?.label ??
									'Every 5 minutes'
								}
								placeholder="Every 3 minutes"
								className={styles.textInput}
							/>
						</View>
						<View className={styles.inputerContainer}>
							<View className={styles.inputHeader}>Set Privacy</View>
							<View className={styles.privacyContainer}>
								<Grid>
									<Grid.Col span={6}>
										<Select
											style={{ zIndex: 2, maxWidth: '100%' }}
											data={['Private', 'Public']}
											value={isPrivate ? 'Private' : 'Public'}
											onChange={(value) => value && setIsPrivate(value === 'Private')}
										/>
									</Grid.Col>
									<Grid.Col span={6}>
										<TextInput
											placeholder="Passcode"
											className={styles.textInput}
											disabled={!isPrivate}
											style={{ width: '100%' }}
											value={newPasscode}
											onChange={(value) => setNewPasscode(value.target.value)}
										/>
									</Grid.Col>
								</Grid>
							</View>
						</View>
						<View className={styles.playlistButtonContainer}>
							<Button
								onClick={onUpdatePlaylist}
								text={'Update Playlist'}
								loading={isUpdatingPlaylist}
								containerClassName={styles.updatePlaylistButtonContainer}
								className={styles.updatePlaylistButton}
								color={colors.primary}
								disabled={!areValidCredentials}
							/>
							<Button
								onClick={() => toggleConfirmDeletePopup(true)}
								text={'Delete Playlist'}
								loading={isDeletingPlaylist}
								containerClassName={styles.updatePlaylistButtonContainer}
								className={styles.updatePlaylistButton}
								color={colors.dark}
							/>
							{errorUpdatingPlaylist && (
								<View className={styles.addToQueueError}>{errorUpdatingPlaylist.message}</View>
							)}
							{errorDeletingPlaylist && (
								<View className={styles.addToQueueError}>{errorDeletingPlaylist.message}</View>
							)}
							{updatePlaylistError && (
								<View className={styles.addToQueueError}>Missing playlist / User token</View>
							)}
						</View>
					</>
				)}
				<Footer />
			</View>
			{playlist && (
				<ConfirmDeletePopup
					toggleRef={(t) => (confirmDeleteRef = t)}
					onSubmit={onDeletePlaylist}
					onCancel={() => toggleConfirmDeletePopup(false)}
					deleteText={playlist.name}
					isLoading={isDeletingPlaylist}
				/>
			)}
		</View>
	)
}
